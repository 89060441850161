import React from "react";
import { Table, Space, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext.js";
import * as _ from "underscore";

class DisabledProviderInstrumentsTable extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;

    this.state = {
      disabledProviderInstruments: this.props.disabledProviderInstruments,
    };
  }

  getColumns = () => {
    return [
      {
        title: "Provider",
        width: "5%",
        dataIndex: "providerName",
      },
      {
        title: "Provider Instrument Name",
        width: "15%",
        dataIndex: "name",
        render: (text, record) => {
          let output = record.name;
          let color = "white";
          return (
            <a
              href={"#/providerinstruments/" + encodeURIComponent(record.name)}
            >
              <p style={{ color: color }}>{output}</p>
            </a>
          );
        },
      },
      {
        title: "Description",
        width: "20%",
        dataIndex: "description",
      },
      {
        title: "Last seen from provider",
        width: "10%",
        dataIndex: "lastSeenFromProvider",
        render: (text, record) => <p>{this.getDate(text)}</p>,
      },
      {
        title: "Provider disabled",
        width: "10%",
        dataIndex: "providerDisabled",
        key: "id",
        render: (text, record) => {
          let output;
          let color;
          switch (text) {
            case 0:
              output = "None";
              color = "rgb(255, 255, 255)";
              break;
            case 1:
              output = "Day 1";
              color = "rgb(255, 215, 165)";
              break;
            case 2:
              output = "Day 2";
              color = "rgb(255, 192, 128)";
              break;
            case 3:
              output = "Day 3";
              color = "rgb(255, 165, 0)";
              break;
            case 4:
              output = "Provider disabled";
              color = "rgb(255, 0, 0)";
              break;
          }
          return <p style={{ color: color }}>{output}</p>;
        },
      },
      {
        title: "Manually disabled",
        width: "10%",
        dataIndex: "manuallyDisabledDate",
        key: "manuallyDisabled",
        render: (text, record) => {
          let output;
          let color;
          let date = "";
          if (record.manuallyDisabledDate) {
            output = "True";
            date = this.getDate(text);
            color = "rgb(255, 165, 0)";
          } else {
            output = "False";
            color = "rgb(255, 255, 255)";
          }
          return (
            <Space>
              <Tooltip title={date}>
                <p style={{ color: color }}>{output}</p>
              </Tooltip>
            </Space>
          );
        },
      },
      {
        title: "Connected to instrument",
        width: "10%",
        dataIndex: "instrument",
        key: ["instrument", "id"],
        render: (text, record) => {
          let output;
          let color;
          if (text) {
            output = record.instrument.publishedName;
            color = "green";
            return (
              <a
                href={
                  "#/instruments/" +
                  encodeURIComponent(record.instrument.publishedName)
                }
              >
                <p style={{ color: color }}>{output}</p>
              </a>
            );
          } else {
            color = "red";
            return <CloseOutlined style={{ fontSize: "22px", color: color }} />;
          }
        },
      },
    ];
  };

  getDate(text) {
    if (text) {
      let date = new Date(text);
      if (date.getFullYear() > 2000) {
        return date.toDateString();
      }
    }
    return "Unknown";
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  render() {
    return (
      <div>
        <Table
          loading={this.props.loading}
          dataSource={this.props.disabledProviderInstruments}
          pagination={true}
          columns={this.getColumns()}
        />
      </div>
    );
  }
}

export default DisabledProviderInstrumentsTable;
