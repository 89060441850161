import React, { Component } from "react";
import { Table, Space, Button, Tooltip } from "antd";

import * as _ from "underscore";
import moment from "moment";
import "antd/dist/antd.dark.css";
import "../main.css";
import { Helpers } from "../helpers/helpers";
import { RenderHelpers } from "../helpers/renderHelpers";
import { AppContext } from "../dataContext";
import {
  SettingOutlined,
  EditOutlined,
  FileFilled,
  LinkOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";

class ProviderInstrumentTable extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      providerInstruments: [],
      providers: [],
    };
  }

  componentDidMount = async () => {
  };

  getProviderInstrumentColumns = (hasWriteRole) => {
    return [
      {
        title: "Provider",
        dataIndex: "provider.name",
        width: 100,
        render: (text, record) => record.provider.name,
      },
      {
        title: "External Name",
        dataIndex: "name",
        width: 150,
        render: RenderHelpers.renderEnabled,
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Bloomberg ID",
        dataIndex: "bloombergId",
        width: 150,
      },
      {
        title: "CMC ID",
        dataIndex: "cmcId",
        width: 150,
      },
      {
        title: "ISIN",
        dataIndex: "isin",
        width: 150,
      },
      {
        title: "Maturity",
        dataIndex: "maturity",
        width: 150,
        render: (text) =>
          text ? moment(text).format(fullDateFormat).toString() : "",
      },
      {
        title: "Metadata",
        dataIndex: "metadata",
        width: 60,
        render: (text) => {
          return text ? (
            <Tooltip title={RenderHelpers.renderMetadata(text)}>
              <FileFilled className="size20" />
            </Tooltip>
          ) : (
            ""
          );
        },
      },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        render: (text) =>
          text ? moment(text).format(fullDateFormat).toString() : "",
      },
      {
        width: 50,
        render: (text, record) =>
          record.used ? (
            <Space>
              <Button
                type="primary"
                shape="circle"
                disabled={record.editingDisabled || !hasWriteRole}
                onClick={() => this.context.showProviderInstrumentEdit(record)}
                icon={<EditOutlined />}
              />
              <a
                href={
                  "#/instruments/" +
                  encodeURIComponent(record.name) +
                  "/providers/" +
                  record.provider.name
                }
              >
                <Tooltip title="Navigate to connected instruments" color="blue">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LinkOutlined />}
                  />
                </Tooltip>
              </a>
            </Space>
          ) : (
            <Space>
              <Button
                type="primary"
                shape="circle"
                disabled={record.editingDisabled || !hasWriteRole}
                onClick={() => this.context.showProviderInstrumentEdit(record)}
                icon={<EditOutlined />}
              />
              <Button
                disabled
                type="primary"
                shape="circle"
                icon={<LinkOutlined />}
              />
            </Space>
          ),
      },
    ];
  };

  render() {
    return (
      <Table
        rowKey="id"
        size="middle"
        onChange={this.props.onChange}
        loading={this.props.loading}
        columns={this.getProviderInstrumentColumns(this.context.hasWriteRole)}
        dataSource={this.props.providerInstruments}
        pagination={{
          size: 10,
          current: this.props.currentPage,
          total: this.props.total,
          showSizeChanger: false,
        }}
      />
    );
  }
}

ProviderInstrumentTable.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentTable);
