import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";
import { DataHelpers } from "../helpers/dataHelpers";
import * as _ from "underscore";

class PricingHubProxy {
  constructor() {
    this.DATA_ACCESS_API_URL = //"http://localhost:5030/api/"
       Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL + "pricinghub/";
    this.APPLICATION = "DASHBOARD";
    console.debug(
      "Dashboard DataAccess API: ",
      Helpers.currentEnvName(),
      this.DATA_ACCESS_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.debug(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }


  async providers() {
    var response;
    try {
      response = await axios.get(this.DATA_ACCESS_API_URL + "providers", {
        timeout: 10000,
        headers: {
          application: this.APPLICATION,
        },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Providers:", response);
    return response.data;
  }

  async getDisabledProviderInstruments(
    search = null,
    toBeDisabledEnum = null,
    connectedToInstrument = null
  ) {
    var cancelled = false;
    var response;
    try {
      const baseURL = this.DATA_ACCESS_API_URL + "disabledproviderinstruments";
      let params = [];
      if (search) {
        params.push(`search=${encodeURIComponent(search)}`);
      }
      if (toBeDisabledEnum?.length > 0) {
        params.push(`toBeDisabled=${encodeURIComponent(toBeDisabledEnum)}`);
      }
      if (connectedToInstrument) {
        params.push(
          `connectedToInstrument=${encodeURIComponent(connectedToInstrument)}`
        );
      }
      const queryString = params.join("&");
      const url = `${baseURL}${queryString ? `?${queryString}` : ""}`;
      console.log("DisabledProviderInstrument search: ", url);
      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      response = await axios.get(url, {
        cancelToken: this.cancelTokenSource.token,
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
      cancelled = true;
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter =
      response && response.status === 200 ? response?.data.counter : 0;
    console.debug(
      "Connectors Coordinator Disabled Provider Instruments Search:",
      search,
      results
    );
    return { disabledProviderInstruments: results, cancelled, counter };
  }

  async getDisabledProviderInstrument(providerInstrumentId) {
    var response;
    try {
      const baseURL =
        this.DATA_ACCESS_API_URL + `disabledproviderinstruments/${providerInstrumentId}`;
      console.log("DisabledProviderInstrument search: ", baseURL);
      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      response = await axios.get(baseURL, {
        cancelToken: this.cancelTokenSource.token,
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug(response);
    var results = response && response.status === 200 ? response?.data : [];
    console.debug(
      "Connectors Coordinator Disabled Provider Instrument Search:",
      results
    );
    return { result: results };
  }

  async providerInstrumentsSearch(providerName, search, page, isTest = false) {
    page = page ?? 1;
    var cancelled = false;
    var response;
    try {
      var url =
        this.DATA_ACCESS_API_URL +
        "providerInstruments/" +
        encodeURIComponent(search) +
        "/providers/" +
        providerName;
      if (providerName && !search)
        url =
          this.DATA_ACCESS_API_URL +
          "providerInstruments/providers/" +
          providerName;
      else if (!providerName && search)
        url =
          this.DATA_ACCESS_API_URL +
          "providerInstruments/" +
          encodeURIComponent(search);
      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      response = await axios.get(
        url +
          "?pageSize=10&page=" +
          page +
          (isTest === null ? "" : `&isTest=${isTest}`),
        {
          cancelToken: this.cancelTokenSource.token,
          timeout: 30000,
          headers: { application: this.APPLICATION },
        }
      );
    } catch (error) {
      console.error(error);
      cancelled = true;
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter =
      response && response.status === 200 ? response?.data.counter : 0;
    console.debug(
      "Data repository Provider Instruments Search:",
      url + "?pageSize=10&page=" + page + (isTest === null)
        ? ""
        : `&isTest=${isTest}`,
      providerName,
      search,
      url,
      results
    );
    return { providerInstruments: results, cancelled, counter };
  }

  async getInstrumentsForProvider(providerName) {
    var response;
    try {
      response = await axios.get(
        this.DATA_ACCESS_API_URL +
          "connectorMaps/providers/" +
          providerName +
          "?includeAllRelevant=true",
        {
          timeout: 10000,
          headers: { application: this.APPLICATION },
        }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository ConnectorMaps:", providerName, results);
    return results;
  }

  async getAllInstruments() {
    var response;
    try {
      response = await axios.get(this.DATA_ACCESS_API_URL + "instruments/all", {
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository All ConnectorMaps:", results);
    return results;
  }

  async getInstrument(name) {
    var response;
    try {
      response = await axios({
        method: "get",
        url:
          this.DATA_ACCESS_API_URL + "instruments/" + encodeURIComponent(name),
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository Instrument:", results);
    return results[0];
  }

  async getTagOptions(visible = null) {
    var response;
    try {
      response = await axios.get(
        `${this.DATA_ACCESS_API_URL}tags?enabled=true${
          visible !== null ? `& visible=${visible}` : ``
        }`,
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    return response.data;
  }

  async getPriceStatusOptions() {
    var response;
    try {
      response = await axios.get(
        this.DATA_ACCESS_API_URL + "pricestatus",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results = response.data;
    return results;
  }

  async instrumentsSearch(search, page) {
    var response;
    page = !page ? 1 : page;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/search?page=${page}`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter = response?.data.counter;
    var convertedConnectorMaps = [];
    for (var x of results) {
      convertedConnectorMaps.push(
        DataHelpers.convert(x)
      );
    }
    var names = DataHelpers.extractInstrumentNamesFromConnectorMaps(
      convertedConnectorMaps
    );
    var payload = { connectorMaps: convertedConnectorMaps, counter, names };
    console.debug(
      "Dashboard data access Instruments search:",
      search,
      payload
    );
    return payload;
  }

  async instrumentSearch(search) {
    var response;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/searchInstrumentNames`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter = response?.data.counter;

    var payload = { instrumentNames: results, counter: counter };
    console.debug(
      "instrumentSearch. Url:",
      this.DATA_ACCESS_API_URL +
        `instruments/searchInstrumentNames`,
      "DashSearch: ",
      search,
      "Paylaod: ",
      payload
    );
    return payload;
  }

  async exportInstrumentsSearch(search) {
    var response;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/search?noPaging=${true}`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var convertedConnectorMaps = [];
    for (var x of results) {
      convertedConnectorMaps.push(
        DataHelpers.convert(x)
      );
    }
    console.debug(
      "Dynamic data repository Export of ConnectorMaps search:",
      search,
      convertedConnectorMaps
    );
    return convertedConnectorMaps;
  }

  async identifyUsedProviderInstruments(ids) {
    var response;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `dash/providerInstruments/used`,
        data: ids,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug(
      "Dynamic data repository Identify Used ProviderInstruments:",
      results
    );
    return results;
  }
}

export default PricingHubProxy;
