import React from "react";
import { Table, Tooltip } from "antd";
import { AppContext } from "../../dataContext.js";
import InstrumentDetails from "./instrumentDetails.js";
import { RenderHelpers } from "../../helpers/renderHelpers";
import moment from "moment";
import InstrumentTableButtons from "./instrumentTableButtons.js";

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "ddd HH:mm:ss";

class InstrumentTable extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;

    this.state = {
      total: 3,
      currentPage: 1,
    };
  }

  getInstrumentColumns = (hasWriteRole) => {
    return [
      {
        title: "",
        dataIndex: "isTest",
        width: 30,
        render: (text, record) =>
          RenderHelpers.remderAutoGenerateSymbol(text, "Auto-generated"),
      },
      {
        title: "Internal Name",
        dataIndex: "publishedName",
        key: "publishedName",
        width: 100,
        render: (text, record) =>
          record.enabled ? (
            <Tooltip title={record.id}>
              <b>{text}</b>
            </Tooltip>
          ) : (
            <Tooltip title={record.id}>
              <font color="gray">{text}</font>
            </Tooltip>
          ),
      },
      {
        title: "Primary",
        dataIndex: "primaryStatusDescription",
        width: 100,
        render: RenderHelpers.renderStatus,
      },
      {
        title: "Secondary",
        dataIndex: "secondaryStatusDescription",
        width: 100,
        render: RenderHelpers.renderStatus,
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Bid",
        dataIndex: "bid",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Ask",
        dataIndex: "ask",
        width: 100,
        render: RenderHelpers.renderBidAsk,
      },
      {
        title: "Last Seen (UTC)",
        dataIndex: "timestamp",
        width: 100,
        render: (text) =>
          text ? (
            <Tooltip title={moment(text).format(fullDateFormat).toString()}>
              {moment(text).format(dateFormat).toString()}
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        title: "MT4",
        dataIndex: "mt4Name",
        width: 100,
      },
      {
        title: "Primary Provider",
        dataIndex: "primaryFeed",
        width: 100,
        render: (text, record) => RenderHelpers.renderProvider(text, record, true),
      },
      {
        title: "Primary Feed",
        dataIndex: "primaryName",
        width: 150,
        render: (text, record) => RenderHelpers.renderFeeds(text, record, true),
      },
      {
        title: "Secondary Provider",
        dataIndex: "secondaryFeed",
        width: 100,
        render: (text, record) => RenderHelpers.renderProvider(text, record, false),
      },
      {
        title: "Secondary Feed",
        dataIndex: "secondaryName",
        width: 150,
        render: (text, record) =>
          RenderHelpers.renderFeeds(text, record, false),
      },
      {
        width: 50,
        render: (text, record) => <InstrumentTableButtons record={record} />,
      },
    ];
  };

  renderDate(text) {
    if (text) {
      let date = new Date(text).toDateString();
      return <b>{date}</b>;
    } else {
      return <b>Unknown</b>;
    }
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  render() {
    return (
      <div>
        <Table
          rowKey="id"
          size="middle"
          onChange={this.props.onChange}
          loading={this.props.loading}
          columns={this.getInstrumentColumns(this.context.hasWriteRole)}
          dataSource={this.props.instruments}
          expandedRowRender={(record, i) => (
            <InstrumentDetails record={record} />
          )}
          pagination={{
            size: 10,
            current: this.props.currentPage,
            total: this.props.total,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}

export default InstrumentTable;
