import React, { Component } from "react";
import {
  Layout,
  Input,
  Form,
  Button,
  Checkbox,
  Divider,
  Space,
  Spin,
  Tooltip,
  Modal,
  Typography,
  Select,
} from "antd";
import * as _ from "underscore";

import "antd/dist/antd.dark.css";
import "../../main.css";

import { AppContext } from "../../dataContext";
import { Helpers } from "../../helpers/helpers";
import { RenderHelpers } from "../../helpers/renderHelpers";
import ConnectorsCoordinatorProxy from "../../proxies/connectorsCoordinatorProxy";
import AutoGenerateSettings from "./autoGenerateSettings";
import { ProviderInstrumentHelper } from "../helpers/providerInstrumentHelper";

const { Content } = Layout;
const { Option } = Select;

class ProviderInstrumentAddOrEdit extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();
    this.props = props;
    this.pricingHubProxy = new ConnectorsCoordinatorProxy();

    this.testSettingsRef = React.createRef();

    const coordinatorAvailable =
      Helpers.currentEnv().COORDINATOR_AVAILABLE === "true";

    this.state = {
      isTest : true,
      loading: true,
      search: undefined,
      providerInstrument: props.providerInstrument,
      coordinatorAvailable: coordinatorAvailable,
      providerDisabled: false,
      isProviderInstrumentAdd: props.isProviderInstrumentAdd,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.setState({ loading: true });
    await this.getProviders();
    let providerInstrument;
    if (this.state.isProviderInstrumentAdd) {
      providerInstrument = ProviderInstrumentHelper.emptyTestProviderInstrument();
    } else {
      providerInstrument = this.extractProviderInstrument(this.props.providerInstrument);
    }
    this.formRef.current.setFieldsValue(providerInstrument);
    this.setState({ loading: false,
      isTest : providerInstrument.isTest
     });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getProviders = async () => {
    this.setState({ loading: true });
    let results = await this.pricingHubProxy.providers();
    this.setState({
      providers: results,
      loading: false,
    });
  };

  getId = (providerName) => {
    for (let provider of this.state.providers) {
      if (provider.name === providerName) {
        return provider.id;
      }
    }
  }

  extractProviderInstrument = (providerInstrument) => {
    console.debug("Extracting existing provider instrument:", providerInstrument)
    providerInstrument = this.testSettingsRef.current.setAutoGenerateValues(providerInstrument);
    this.getDisabledProperties(providerInstrument);
    return providerInstrument;
  };

  cancelEdit = () => {
    this.formRef.current.resetFields();
    this.context.updateContext("isProviderInstrumentAddOrEditVisible", false);
  };

  showConfirmationModal = (name) => {
    this.setState({ deleteLoading: true });
    this.setState({ selectedProvider: name });
    this.setState({ isModalVisible: true });
  };

  onDeleteOk = async () => {
    this.setState({ confirmLoading: true });
    await this.pricingHubProxy.deleteProviderInstrument(
      this.props.providerInstrument?.id
    );
    this.setState({ isModalVisible: false });
    this.setState({ confirmLoading: false });
    this.setState({ deleteLoading: false });
    this.context.updateContext("isProviderInstrumentAddOrEditVisible", false);
  };

  getDisabledProperties = async (providerInstrument) => {
    const disabledProviderInstrument =
      await this.pricingHubProxy.getDisabledProviderInstrument(
        providerInstrument.id
      );
    if (disabledProviderInstrument.result?.providerDisabled) {
      this.setState({ providerDisabled: true });
    } else {
      this.setState({ providerDisabled: false });
    }
  };

  onDeleteCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ deleteLoading: false });
  };

  save = async () => {
    let errors = this.formRef.current.getFieldsError();
    if (_.every(errors, (x) => x["errors"].length === 0)) {
      this.setState({ saveLoading: true });
      if (this.state.isProviderInstrumentAdd === false) {
        this.props.providerInstrument.editingDisabled = true;
      }
      let providerInstrument = this.formRef.current.getFieldsValue();
      let convertedProviderInstrument =
        ProviderInstrumentHelper.extractProviderInstrument(
          providerInstrument,
          this.getId(providerInstrument.provider.name),
          this.state.isTest
        );

      if (this.state.isProviderInstrumentAdd) {
        console.log("Adding new testprovider instrument");
        await this.pricingHubProxy.addProviderInstrument( // TODO : Need to make these methods
          convertedProviderInstrument
        );
      } else 
        console.log("Updating non-test provider instrument");
        await this.pricingHubProxy.updateProviderInstrument(
          convertedProviderInstrument
        );
      } 
      this.setState({ saveLoading: false });
      this.context.updateContext("isProviderInstrumentAddOrEditVisible", false);
  };

  toggleIsTest = (value) => {
    this.setState({ isTest: value.target.checked });
    this.formRef.current.setFieldsValue({
      isTest: value.target.checked,
    });
  };
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    const onFinishFailed = (errorInfo) => {
      console.debug("Failed:", errorInfo);
    };

    return (
      <Content style={{ padding: "0 5px" }}>
        <Spin spinning={this.state.loading}>
          <Form
            ref={this.formRef}
            {...layout}
            onFinish={this.save}
            onFinishFailed={onFinishFailed}
          >
            {!this.state.coordinatorAvailable && (
              <Form.Item {...tailLayout}>
                <h2 className="editingDisabledWarning">{`Cannot edit provider instruments in ${Helpers.currentEnvName().toLowerCase()} environment.`}</h2>
              </Form.Item>
            )}
            <Form.Item name="id" hidden="true"></Form.Item>
            <Form.Item
              label="Provider"
              name={["provider", "name"]}
              rules={[{ required: true, message: "Provider is mandatory!" }]}
            >
              <Select disabled={this.state.isProviderInstrumentAdd === false}>
                {this.state.providers?.map((x) => (
                  <Option key={x.id} value={x.name} label={x.name}>
                    <b>{x.name}</b>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Name is mandatory!" },
                ({ getFieldValue }) => ({
                  validator: async (_, value) => {
                    let provider = getFieldValue(["provider", "name"]);
                    let isTest = getFieldValue("isTest");
                    let originalName = this.props.providerInstrument?.name;
                    if (value && provider) {
                      let result =
                        await this.pricingHubProxy.providerInstrumentsSearch(
                          provider,
                          encodeURIComponent(value),
                          null,
                          isTest
                        );
                      if (result.counter) {
                        for (let val of result.providerInstruments)
                          if (
                            (
                              (value).toLowerCase() ===
                                val.name.toLowerCase() &&
                              (value).toLowerCase() !==
                                originalName.toLowerCase()) ||
                            (!isTest &&
                              value === val.name &&
                              value !== originalName)
                          ) {
                            return Promise.reject(
                              new Error("Name needs to be unique!")
                            );
                          }
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Typography.Text strong type="warning">
                {this.state.providerDisabled
                  ? "This provider instrument is currently unavailable from the provider and cannot be enabled."
                  : ""}
              </Typography.Text>
            </Form.Item>
            <Form.Item {...tailLayout} name="enabled" valuePropName="checked">
              <Checkbox disabled={this.state.providerDisabled}>
                Enabled
              </Checkbox>
            </Form.Item>
            <Form.Item {...tailLayout} name="isTest" valuePropName="checked">
              <Checkbox onChange={this.toggleIsTest}>Test Provider Instrument</Checkbox>
            </Form.Item>
            <AutoGenerateSettings
              ref={this.testSettingsRef}
              shouldRender={this.state.isTest}
            />
            <Divider orientation="left">Provider IDs</Divider>
            <div>
              <Form.Item label="Bloomberg ID" name="bloombergId">
                <Input />
              </Form.Item>
              <Form.Item label="CMC ID" name="cmcId">
                <Input />
              </Form.Item>
              <Form.Item label="Isin" name="isin">
                <Input />
              </Form.Item>
              {this.props.providerInstrument?.metadata && (
                <Space>
                  <Divider orientation="left">Metadata</Divider>
                  {RenderHelpers.renderMetadataIntoForm(
                    this.props.providerInstrument?.metadata
                  )}
                </Space>
              )}
            </div>
            <Divider></Divider>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  key="delete"
                  danger
                  disabled={
                    !this.props.providerInstrument?.isTest ||
                    !this.state.coordinatorAvailable ||
                    this.props.providerInstrument.used
                  }
                  onClick={this.showConfirmationModal}
                  loading={this.state.deleteLoading}
                >
                  Delete
                </Button>
                <Button
                  key="cancel"
                  disabled={this.state.saveLoading}
                  onClick={this.cancelEdit}
                >
                  Cancel
                </Button>
                <Tooltip
                  title={
                    !this.state.coordinatorAvailable
                      ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                      : ""
                  }
                  color="red"
                >
                  <Button
                    key="save"
                    type="primary"
                    htmlType="submit"
                    disabled={
                      this.state.deleteLoading ||
                      !this.state.coordinatorAvailable
                    }
                    loading={this.state.saveLoading}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
        <Modal
          maskClosable={false}
          closable={false}
          title="Confirmation"
          open={this.state.isModalVisible}
          onOk={this.onDeleteOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.onDeleteCancel}
        >
          <p>
            Do you really want to delete the selected test provider instrument?
          </p>
        </Modal>
      </Content>
    );
  }
}

ProviderInstrumentAddOrEdit.contextType = AppContext;
export default ProviderInstrumentAddOrEdit;
