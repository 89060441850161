import React from "react";
import { NavLink } from "react-router-dom";
import Price from "../components/priceBackground";
import "../main.css";
import { Tooltip, Row, Col, Button, Space } from "antd";
import {
  WarningFilled,
  ControlOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { MarketHelpers } from "./marketHelpers";

export class RenderHelpers {
  static renderBidAsk = (text, x) => (
    <Price arrow={x.arrow} text={text} dp={x.dp}></Price>
  );

  static renderFeeds = (text, record, isPrimary) => {
    const {
        isPrimaryActive,
        primaryEnabled,
        secondaryEnabled,
        primaryFeed,
        secondaryFeed,
        primaryIsTest,
        secondaryIsTest,
    } = record;

    const isActive = isPrimary ? isPrimaryActive : !isPrimaryActive;
    const enabled = isPrimary ? primaryEnabled : secondaryEnabled;
    const feed = isPrimary ? primaryFeed : secondaryFeed;
    const isTest = "?isTest=" + (isPrimary ? primaryIsTest : secondaryIsTest);

    const renderLink = (content, tooltip) => (
        <Space>
            <NavLink
                to={`/providerInstruments/${encodeURIComponent(text)}/providers/${feed}${isTest}`}
            >
                {tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content}
            </NavLink>
        </Space>
    );

    const color = isActive && enabled ? "#49AA19" : "gray";

    if (!text) {
        return null;
    }
    return enabled ? renderLink(<font color={color}>{text}</font>) : renderLink(<strike><font color={color}>{text}</font></strike>, "Disabled feed");
  };

  static renderProvider =(text, record, isPrimary) => {
    if(!text){
      return null;
    }
    return <p>{`${text} [${isPrimary? record.primaryFeedConnectorInstance : record.secondaryFeedConnectorInstance}]`}</p>
  }

  static renderText = (text, record) => {
    return text ? record.enabled ? text : <font color="gray">{text}</font> : "";
  };

  static renderEnabled = (text, record) => {
    if (text)
      if (record.enabled) {
        if (record.toBeDisabled > 0)
          return (
            <Tooltip title={record.id}>
              <font color="#D32029">{text}</font>
            </Tooltip>
          );
        else
          return (
            <Tooltip title={record.id}>
              <font color="#49AA19">{text}</font>
            </Tooltip>
          );
      } else
        return (
          <Tooltip title={record.id}>
            {" "}
            <strike>
              <font color="gray">{text}</font>
            </strike>
          </Tooltip>
        );
    else return "";
  };

  static renderStatus = (text, record) => {
    var colour = record.enabled
      ? text === "Normal"
        ? "#49AA19"
        : text === "Indicative"
          ? "#D84A1B"
          : "#D32029"
      : "gray";
    return !text ? "" : <font color={colour}>{text}</font>;
  };

  static renderMmStatus = (text, status) => {
    var colour =
      status === "Normal"
        ? "#49AA19"
        : text === "Indicative"
          ? "#D84A1B"
          : "#D32029";
    return !text ? "" : <font color={colour}>{text}</font>;
  };

  static renderFeedStatus = (text, record) => {
    var colour =
      text === "Running"
        ? "#49AA19"
        : text === "Starting"
          ? "#D84A1B"
          : "#D32029";
    return !text ? (
      ""
    ) : (
      <font className="bold16" color={colour}>
        {text}
      </font>
    );
  };

  static renderAB = (text, record) => {
    var colour =
      record.hostAStatus === "Running" &&
        (!record.hostB || record.hostBStatus === "Running")
        ? "#49AA19"
        : record.isActive
          ? "#D84A1B"
          : "#D32029";
    return (
      <font className="bold16" color={colour}>
        {text ? "A" : "B"}
      </font>
    );
  };

  static renderWarning = (text, record) => {
    var isPassive =
      record.hostAStatus !== "Running" &&
      (!record.hostB || record.hostBStatus !== "Running");
    return isPassive ? <WarningFilled className="bold16" /> : "";
  };

  static remderAutoGenerateSymbol = (isTrue, toolTip) => {
    return isTrue ?
      (<Tooltip title={toolTip}>
        <ControlOutlined  className="bold16" />
      </Tooltip >) : "";
  };

  static renderTrueFalse = (text) => {
    return text ? (
      <CheckCircleFilled className="size20" twoToneColor="#52c41a" />
    ) : (
      <CloseCircleFilled className="size20" twoToneColor="#eb2f96" />
    );
  };

  static renderConnectorName = (text, record) => {
    var colour = record.connectorStatus === "Running" ? "#49AA19" : "#D32029";
    return record.connector2 ? (
      <font color={colour}>
        {text} {record.connector2}
      </font>
    ) : (
      <font color={colour}>{text}</font>
    );
  };

  static renderConnector2Name = (text, record) => {
    var colour = record.connectorStatus2 === "Running" ? "#49AA19" : "#D32029";
    return <font color={colour}>{text}</font>;
  };

  static renderMetadata = (text) => {
    var list = [];
    var metadata = JSON.parse(text);
    for (var key in metadata) {
      list.push(
        <div key={key}>
          {key}: <b>{metadata[key]}</b>
        </div>
      );
    }
    return list;
  };

  static renderMetadataIntoForm = (text) => {
    var list = [];
    if (text) {
      var metadata = JSON.parse(text);
      for (var key in metadata) {
        list.push(
          <Row style={{ padding: "5px 0 0 0" }}>
            <Col align="right" span={8}>
              <div style={{ padding: "0 10px 0 0" }}>{key} :</div>
            </Col>
            <Col span={16}>
              <b>{metadata[key]}</b>
            </Col>
          </Row>
        );
      }
    }
    return list;
  };

  static renderProviderInstruments = (text, name) => {
    text = text ? text : "-";
    var colour =
      name === "cmcId"
        ? "#52c41a"
        : name === "bloombergId"
          ? "#fa8c16"
          : "#ffff00";
    return (
      <font className="bold16" color={colour}>
        {" "}
        {text}
      </font>
    );
  };

  static renderSqlTableDependencyStatus = (text, record) => {
    var colour = text === "Normal" ? "#49AA19" : "#D32029";
    return !text ? (
      ""
    ) : (
      <font className="bold16" color={colour}>
        {text}
      </font>
    );
  };

  static renderSqlTableDependencyRawStatus = (text, record) => {
    var colour =
      record.rawStatus === 3
        ? "#49AA19"
        : record.rawStatus < 3
          ? "#D84A1B"
          : "#D32029";
    return !text ? (
      ""
    ) : (
      <font className="bold16" color={colour}>
        {text}
      </font>
    );
  };

  static renderSqlTableDependencyWarning = (text, record) => {
    return record.statusDescription !== "Normal" ? (
      <WarningFilled className="bold16" />
    ) : (
      ""
    );
  };

  static renderAssociatedButtons = (record, onChange) => {
    if (record.prcGenRuleName !== "DIFFERENTIAL") {
      return (
        <Tooltip title="Associated instrument">
          <a
            href={
              "#/instruments?operation=equals&" +
              MarketHelpers.associatedInstruments(
                record.prcGenRawSourceSymbol,
                record.connector,
                "name=",
              )
                .join("&")
                .toLowerCase()
            }
          >
            <Button type="primary" shape="circle" icon={<SettingOutlined />} />
          </a>
        </Tooltip>
      );
    } else {
      var allMarkets = record.parts.every((x) => x.isMarket);
      if (allMarkets)
        return (
          <Tooltip title="Associated markets">
            <Button
              type="primary"
              shape="circle"
              icon={<SettingOutlined />}
              onClick={() =>
                onChange(
                  null,
                  null,
                  null,
                  MarketHelpers.associatedMarkets(record)
                )
              }
            />
          </Tooltip>
        );
      else {
        var market = record.parts.filter((x) => x.isMarket)[0];
        var instrument = record.parts.filter((x) => !x.isMarket)[0];
        return (
          <Space>
            <Tooltip title="Associated instrument">
              <a
                href={
                  "#/instruments?operation=equals&name=" +
                  encodeURIComponent(
                    (record.prcGenRawSourceSymbol.includes(instrument.name)
                      ? record.connector
                      : record.connector2) +
                    "." +
                    instrument.name
                  ).toLowerCase()
                }
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SettingOutlined />}
                />
              </a>
            </Tooltip>
            <Tooltip title="Associated market">
              <Button
                type="primary"
                shape="circle"
                icon={<SettingOutlined />}
                onClick={() => onChange(null, null, null, [market.name])}
              />
            </Tooltip>
          </Space>
        );
      }
    }
  };
}
