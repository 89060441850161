export class InstrumentPageDataHelper {
  static removePrimaryOrSecondary(connectorMap, deletedConnectorMapId) {
    var toBeRemoved = [];
    if (connectorMap.primaryConnectorMapId === deletedConnectorMapId) {
      var found = connectorMap.constituentPrices.filter(
        (x) =>
          x.name === connectorMap.primaryName &&
          x.feed === connectorMap.primaryFeed
      )[0];
      if (found) toBeRemoved.push(found);
      connectorMap.primaryConnectorMapId = undefined;
      connectorMap.primaryId = undefined;
      connectorMap.primaryName = undefined;
      connectorMap.primaryFeed = undefined;
      connectorMap.primaryEnabled = undefined;
      connectorMap.primaryIsTest = undefined;
      connectorMap.primaryStatus = undefined;
      connectorMap.primaryStatusDescription = undefined;
    } else if (connectorMap.secondaryConnectorMapId === deletedConnectorMapId) {
      found = connectorMap.constituentPrices.filter(
        (x) =>
          x.name === connectorMap.secondaryName &&
          x.feed === connectorMap.secondaryFeed
      )[0];
      if (found) toBeRemoved.push(found);
      connectorMap.secondaryConnectorMapId = undefined;
      connectorMap.secondaryId = undefined;
      connectorMap.secondaryName = undefined;
      connectorMap.secondaryFeed = undefined;
      connectorMap.secondaryEnabled = undefined;
      connectorMap.secondaryIsTest = undefined;
      connectorMap.secondaryStatus = undefined;
      connectorMap.secondaryStatusDescription = undefined;
    }
    toBeRemoved.forEach((x) => {
      var index = connectorMap.constituentPrices.indexOf(x);
      connectorMap.constituentPrices.splice(index, 1);
    });
  }

  static instrumentWithFeedsUpdate(existing) {
    var found = existing.constituentPrices.filter(
      (x) =>
        x.name === existing.primaryName &&
        x.feed === existing.primaryFeed
    )[0];
    if (!found)
      existing.constituentPrices.push({
        name: existing.primaryName,
        feed: existing.primaryFeed
      });
    found = existing.constituentPrices.filter(
      (x) =>
        x.name === existing.secondaryName &&
        x.feed === existing.secondaryFeed
    )[0];
    if (existing.secondaryConnectorMapId && !found)
      existing.constituentPrices.push({
        name: existing.secondaryName,
        feed: existing.secondaryFeed
      });
    var toBeRemoved = existing.constituentPrices.filter(
      (x) =>
        x.name !== existing.primaryName &&
        x.name !== existing.secondaryName
    );
    toBeRemoved.forEach((x) => {
      var index = existing.constituentPrices.indexOf(x);
      existing.constituentPrices.splice(index, 1);
    });
  }

  static removeSecondaryFeed(instrument) {
    instrument.secondaryConnectorMapId = undefined;
    instrument.secondaryId = undefined;
    instrument.secondaryName = undefined;
    instrument.secondaryFeed = undefined;
    instrument.secondaryEnabled = undefined;
    instrument.secondaryStatus = undefined;
    instrument.secondaryStatusDescription = undefined;
  }

  static merge(instrument, modifiedInstrument) {
    instrument.enabled = modifiedInstrument.enabled;
    instrument.publishedName = modifiedInstrument.publishedName;
    instrument.publishedSource = modifiedInstrument.publishedSource;
    instrument.description = modifiedInstrument.description;
    instrument.mt4Name = modifiedInstrument.mt4Name;
    instrument.timeoutFailover =
      modifiedInstrument.instrumentTimeoutFailover;
    instrument.timeoutInSec = modifiedInstrument.instrumentTimeoutInSec;
    instrument.timeoutInSecAtNight =
      modifiedInstrument.instrumentTimeoutInSecAtNight;
    instrument.backwardationFix = modifiedInstrument.backwardationFix;
    instrument.zeroPriceFilter = modifiedInstrument.zeroPriceFilter;
    instrument.maxChangeMovementValue =
      modifiedInstrument.maxChangeMovementValue;
    instrument.maxChangeNumberOfPrices =
      modifiedInstrument.maxChangeNumberOfPrices;
    instrument.maxChangeDiscard = modifiedInstrument.maxChangeDiscard;
    instrument.tags = modifiedInstrument.tags;
    instrument.dp = modifiedInstrument.dp;
    instrument.isTest = modifiedInstrument.isTest;
  }

  static mergeInstrumentWithProviderInstrument(
    instrument,
    providerInstrument
  ) {
    if (instrument.secondaryId === providerInstrument?.id) {
      var found = instrument.constituentPrices.filter(
        (x) =>
          x.name === instrument.secondaryName &&
          x.feed === instrument.secondaryFeed
      )[0];
      if (found) found.name = providerInstrument.name;
      instrument.secondaryName = providerInstrument.name;
      instrument.secondaryEnabled = providerInstrument.enabled;
    } else if (instrument.primaryId === providerInstrument?.id) {
      found = instrument.constituentPrices.filter(
        (x) =>
          x.name === instrument.primaryName &&
          x.feed === instrument.primaryFeed
      )[0];
      if (found) found.name = providerInstrument.name;
      instrument.primaryName = providerInstrument.name;
      instrument.primaryEnabled = providerInstrument.enabled;
      instrument.primaryIsTest = providerInstrument.isTest;
    }
  }

  static mergeExistingWithNewInstrument(existingInstrument, newInstrument) {
    existingInstrument.enabled = newInstrument.enabled;
    existingInstrument.publishedName = newInstrument.publishedName;
    existingInstrument.publishedSource = newInstrument.publishedSource;
    existingInstrument.description = newInstrument.description;
    existingInstrument.mt4Name = newInstrument.mt4Name;
    existingInstrument.timeoutFailover = newInstrument.instrumentTimeoutFailover;
    existingInstrument.timeoutInSec = newInstrument.instrumentTimeoutInSec;
    existingInstrument.timeoutInSecAtNight = newInstrument.instrumentTimeoutInSecAtNight;
    existingInstrument.backwardationFix = newInstrument.backwardationFix;
    existingInstrument.zeroPriceFilter = newInstrument.zeroPriceFilter;
    existingInstrument.maxChangeMovementValue = newInstrument.maxChangeMovementValue;
    existingInstrument.maxChangeNumberOfPrices = newInstrument.maxChangeNumberOfPrices;
    existingInstrument.maxChangeDiscard = newInstrument.maxChangeDiscard;
    existingInstrument.tags = newInstrument.tags;
    existingInstrument.dp = newInstrument.decimalPlaces;
    existingInstrument.isTest = newInstrument.isTest;
  }

  static applyRedisPricesToInstruments(redisPrices, instruments) {
    if (redisPrices) {
      redisPrices.forEach((price) => {
        var all = instruments?.filter(
          (y) => y.publishedName === price.internalId
        );
        all.forEach((found) => {
          found.timestamp = price.time;
          found.arrow =
            found.bid < price.bid ? "u" : found.ask === price.bid ? "e" : "d";
          found.bid = price.bid;
          found.ask = price.ask;
        });
      });
    }
  }
}
