import React, { Component } from "react";
import {
  Layout,
  Table,
  Button,
  Input,
  Tree,
  Typography,
  Space,
  Tooltip,
  Select,
} from "antd";
import { SearchOutlined, CarryOutOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as _ from "underscore";
import moment from "moment";
import * as lodash from "lodash";
import "antd/dist/antd.dark.css";
import "../main.css";
import DynamicDataRepositoryRedisDbProxy from "../proxies/redisDbProxy";
import DataRepositoryProxy from "../proxies/dataRepositoryProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import DynamicDataRepositoryRedisProxy from "../proxies/redisProxy";
import DynamicDataRepositoryKafkaProxy from "../proxies/kafkaProxy";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import { DataHelpers } from "../helpers/dataHelpers";
import { MarketHelpers } from "../helpers/marketHelpers";
import { Helpers } from "../helpers/helpers";
import { RenderHelpers } from "../helpers/renderHelpers";
import { AppContext } from "../dataContext";
import MarketDetails from "./marketDetails";
import DashboardDataAccessProxy from "../proxies/dashboardDataAccessProxy.js";
const { Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "ddd HH:mm:ss";

class MarketMonitor extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this.urlSearch = new URLSearchParams(this.props.location.search);
    this.dataRepositoryProxy = new DataRepositoryProxy();
    this.dashboardDataAccessProxy = new DashboardDataAccessProxy();
    this.pricingHubProxy = new ConnectorsCoordinatorProxy();
    this.redisProxy = new DynamicDataRepositoryRedisProxy(
      this.onRedis,
      this.onRedisReconnect
    );
    this.kafkaProxy = new DynamicDataRepositoryKafkaProxy(
      this.onKafkaPrice,
      undefined,
      this.onKafkaFeed,
      this.onKafkaReconnect
    );
    this.redisDbProxy = new DynamicDataRepositoryRedisDbProxy(
      {
        onMarket: this.onMarket,
        onMarketTradeSettings: this.onMarketTradeSettings,
      }
    );
    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy({});

    this.throttledSearch = _.throttle(this.search, 2000, {
      leading: false,
    });

    this.markets = [];
    this.marketQuotes = [];
    this.names = [];

    this.state = {
      search: undefined,
      treeData: [],
      markets: [],
      marketGroups: [],
      marketSuperGroups: [],
      whiteLabels: [],
      loading: true,
      selectedPlatform: undefined,
      selectedRiskGroup: undefined,
      selectedStatus: undefined,
      selectedRegion: undefined,
      platformsLoading: true,
      statusesLoading: true,
      riskGroupsLoading: true,
      regionsLoading: true,
      filteredOutData: undefined,
      openPositionsLoading: true,
      expandedKeys: [],
      operationValue: "contains",
      columns: [
        {
          title: "Provider",
          dataIndex: "connector",
          key: "connector",
          width: 120,
          render: (text, record) => {
            if (record.prcGenRuleName !== "DIFFERENTIAL")
              return RenderHelpers.renderConnectorName(text, record);
            else {
              return (
                <div>
                  {record.parts[0].isMarket
                    ? "MARKET"
                    : RenderHelpers.renderConnectorName(text, record)}
                  <br />
                  {record.parts[1].isMarket
                    ? "MARKET"
                    : RenderHelpers.renderConnector2Name(
                      record.connector2,
                      record
                    )}
                </div>
              );
            }
          },
        },
        {
          title: "Instrument status",
          dataIndex: "priceStatus",
          key: "priceStatus",
          width: 100,
          render: (text, record) => {
            if (record.prcGenRuleName === "DIFFERENTIAL") {
              return (
                <div>
                  {record.parts[0].isMarket
                    ? record.parts[0].name
                    : RenderHelpers.renderMmStatus(
                      MarketHelpers.associatedInstruments(
                        record.prcGenRawSourceSymbol,
                        record.connector,
                        "",
                        false
                      ),
                      record.priceStatus
                    )}
                  <br />
                  {record.parts[1].isMarket
                    ? record.parts[1].name
                    : RenderHelpers.renderMmStatus(
                      MarketHelpers.associatedInstruments(
                        record.prcGenRawSourceSymbol2,
                        record.connector,
                        "",
                        false
                      ),
                      record.priceStatus
                    )}
                </div>
              );
            } else
              return RenderHelpers.renderMmStatus(
                MarketHelpers.associatedInstruments(
                  record.prcGenRawSourceSymbol,
                  record.connector,
                  "",
                  false
                ),
                record.priceStatus
              );
          },
        },
        {
          title: "Status",
          dataIndex: "tradeable",
          key: "tradeable",
          width: 70,
          render: (text, record) => {
            return !record.isDeleted && record.tradeable && record.callOnly ? (
              <Tooltip title="Call Only">
                <img
                  alt="cmc"
                  width={24}
                  opacity={0.5}
                  src="./icons/call_orange.svg"
                />{" "}
              </Tooltip>
            ) : !record.isDeleted && record.tradeable ? (
              <Tooltip title="Tradeable">
                <img
                  alt="cmc"
                  width={24}
                  opacity={0.5}
                  src="./icons/tradeable_green.svg"
                />{" "}
              </Tooltip>
            ) : record.isDeleted ||
              (!record.tradeable &&
                (record.exchangeOpen || !record.appearOnWeb)) ? (
              <Tooltip title="Non-tradeable">
                <img
                  alt="cmc"
                  width={24}
                  opacity={0.5}
                  src="./icons/tradeable_red.svg"
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title="Non-tradeable">
                <img
                  alt="cmc"
                  width={24}
                  opacity={0.5}
                  src="./icons/zzz_orange.svg"
                />{" "}
              </Tooltip>
            );
          },
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          width: 300,
          sorter: (a, b) => Helpers.sorter(a, b, (x) => x.name),
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Bid",
          dataIndex: "bid",
          key: "bid",
          width: 100,
          render: RenderHelpers.renderBidAsk,
        },
        {
          title: "Ask",
          dataIndex: "ask",
          key: "ask",
          width: 100,
          render: RenderHelpers.renderBidAsk,
        },
        {
          title: "Low",
          dataIndex: "low",
          key: "low",
        },
        {
          title: "High",
          dataIndex: "high",
          key: "high",
        },
        {
          title: "Open",
          dataIndex: "open",
          key: "open",
        },
        {
          title: "Close",
          dataIndex: "close",
          key: "close",
        },
        {
          title: "Last Seen (UTC)",
          dataIndex: "timestamp",
          key: "timestamp",
          width: 180,
          render: (text) =>
            text ? (
              <Tooltip title={moment(text).format(fullDateFormat).toString()}>
                {moment(text).format(dateFormat).toString()}
              </Tooltip>
            ) : (
              ""
            ),
        },
        {
          width: 180,
          render: (text, record) => {
            return (
              <Space>
                {
                  <Tooltip title="Trade on web">
                    <Button
                      shape="circle"
                      onClick={() =>
                        this.updateTradeOnWeb(record.id, record.tradeOnWeb)
                      }
                    >
                      {record.tradeOnWeb ? (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/trade_grey.svg"
                        />
                      ) : (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/trade_orange.svg"
                        />
                      )}
                    </Button>
                  </Tooltip>
                }
                {
                  <Tooltip
                    title="Call only"
                    onClick={() =>
                      this.updateCallOnly(record.id, record.callOnly)
                    }
                  >
                    <Button shape="circle">
                      {record.callOnly ? (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/call_orange.svg"
                        />
                      ) : (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/call_grey.svg"
                        />
                      )}
                    </Button>
                  </Tooltip>
                }
                {
                  <Tooltip title="Appears on web">
                    <Button
                      shape="circle"
                      onClick={() =>
                        this.updateAppearOnWeb(record.id, record.appearOnWeb)
                      }
                    >
                      {record.appearOnWeb ? (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/www_grey.svg"
                        />
                      ) : (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/www_orange.svg"
                        />
                      )}
                    </Button>
                  </Tooltip>
                }
                {
                  <Tooltip
                    title={
                      record.noLongPosition
                        ? "No Long Positions"
                        : "Long Positions"
                    }
                  >
                    <Button
                      shape="circle"
                      onClick={() =>
                        this.updateLongPosition(
                          record.id,
                          record.noLongPosition
                        )
                      }
                    >
                      {record.noLongPosition ? (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/long_green.svg"
                        />
                      ) : (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/long_grey.svg"
                        />
                      )}
                    </Button>
                  </Tooltip>
                }
                {
                  <Tooltip
                    title={
                      record.noShortSelling
                        ? "No Short Positions"
                        : "Short Positions"
                    }
                  >
                    <Button
                      shape="circle"
                      onClick={() =>
                        this.updateShortPosition(
                          record.id,
                          record.noShortSelling
                        )
                      }
                    >
                      {record.noShortSelling ? (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/short_red.svg"
                        />
                      ) : (
                        <img
                          alt="cmc"
                          width={18}
                          opacity={1}
                          src="./icons/short_grey.svg"
                        />
                      )}
                    </Button>
                  </Tooltip>
                }
                {RenderHelpers.renderAssociatedButtons(record, this.onChange)}
              </Space>
            );
          },
        },
      ],
    };
  }

  onMarket = (market) => {
    if (this.markets) {
      let found = this.markets.filter((x) => x.id === market.id)[0];
      if (found) {
        found.prcGenBetPer = market.prcGenBetPer;
        found.prcGenFairValue = market.prcGenFairValue;
        found.noShortSelling = market.noShortSelling;
        found.isDeleted = market.isDeleted;
      }
      this.localSetState();
    }
  };

  onMarketTradeSettings = (marketTradeSettings) => {
    if (this.markets) {
      let found = this.markets.filter(
        (x) => x.id === marketTradeSettings.id
      )[0];
      if (found) {
        found.tradeOnWeb = marketTradeSettings.tradeOnWeb;
        found.appearOnWeb = marketTradeSettings.appearOnWeb;
        found.callOnly = marketTradeSettings.callOnly;
        found.noLongPosition = marketTradeSettings.noLongPosition;
        found.statusID = marketTradeSettings.statusId;
        this.localSetState();
      }
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.search(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.search(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.reset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex] !== null
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "yellow", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  reset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onSelect = async (selectedKeys, info) => {
    this.setState({ search: [] });
    this._updateContext([], undefined, selectedKeys, undefined);
    this.mqs = undefined;
    let foundSuperGroup = this.state.marketSuperGroups.filter(
      (x) => x.name.trim() === selectedKeys[0]
    )[0];
    console.debug("Selected: ", selectedKeys, info, foundSuperGroup);
    if (!foundSuperGroup) {
      this.setState({
        hideTable: false,
        loading: true,
        selectedKeys: selectedKeys,
      });
      await this.redisProxy.connect();
      await this.kafkaProxy.connect();
      this.markets =
        selectedKeys.length === 0
          ? []
          : this.allMarkets.filter(
            (x) => x.marketGroupId === selectedKeys[0]
          );

      let marketQuotes = [];
      if (selectedKeys.length > 0) {
        await this._updateMarkets(this.markets);
        this._updateMarketsWithRedisPrices(this.markets);
        Promise.all(
          this.markets.map(async (x) => {
            let mqs = await this.dataRepositoryProxy.marketQuotes(x.id);
            x.marketQuotes = mqs;
            marketQuotes = marketQuotes.concat(mqs);
          })
        ).then(async () => {
          this.marketQuotes = marketQuotes;
          let initial = marketQuotes.filter(
            (x) => x.whiteLabelId === 0 && x.clientId === 0
          );
          let initialPrices = await this.redisProxy.priceLive(
            initial.map((x) => x.id)
          );
          initialPrices.forEach(async (x) => await this.onRedis(x));
          this.mqUpdate(initial);
          this.kafkaProxy.subscribeAndOverride(MarketHelpers.extractInstrumentNamesFromMarkets(this.markets));
          this.setState({ loading: false });
          this.updateUrl([], selectedKeys[0]);
        });
      } else {
        await this.kafkaProxy.unsubscribe();
        await this.redisProxy.unsubscribe();
        this.setState({ loading: false });
        this.updateUrl(this.state.search, null);
      }
      this.localSetState();
    } else {
      this.setState({
        foundSuperGroup: foundSuperGroup,
        hideTable: true,
      });
      this.onExpand(undefined, selectedKeys);
    }
  };

  _updateMarkets = async (markets) => {
    let tradeableData = await this.redisProxy.tradableMarket(
      markets.map((x) => x.id)
    );
    tradeableData.forEach((x) => {
      let market = markets.filter((y) => y.id === x.marketId)[0];
      if (market) {
        market.exchangeOpen = x.exchangeOpen;
        market.isHoliday = x.isHoliday;
        market.isInTradeSession = x.isInTradeSession;
      }
    });

    let marketTradeSettings =
      await this.dataRepositoryProxy.marketTradeSettings(
        markets.map((x) => x.id)
      );

    marketTradeSettings.forEach((x) => {
      let market = markets.filter((y) => y.id === x.id)[0];
      if (market) {
        market.tradeOnWeb = x.tradeOnWeb;
        market.appearOnWeb = x.appearOnWeb;
        market.callOnly = x.callOnly;
        market.noLongPosition = x.noLongPosition;
      }
    });
  };

  onManualSelect = async (
    searchParameters,
    isStrict = false
  ) => {
    await this.redisProxy.connect();
    await this.kafkaProxy.connect();
    searchParameters = searchParameters.filter((x) => x && x !== "");
    console.debug(
      "Selected instrument or market: ",
      searchParameters,
      isStrict
    );
    this.mqs = undefined;
    this.markets = this.allMarkets.filter((x) => {
      return isStrict
        ? searchParameters.includes(x.id.toString()) ||
        searchParameters.some((y) => x.name.toLowerCase() === y)
        : searchParameters.some((y) =>
          x.prcGenRawSourceSymbol.toLowerCase().includes(y)
        ) ||
        searchParameters.some((y) =>
          x.prcGenRawSourceSymbol2?.toLowerCase().includes(y)
        ) ||
        searchParameters.some((y) => x.name.toLowerCase().includes(y)) ||
        searchParameters.includes(x.id.toString());
    });

    searchParameters.forEach(async (x) => {
      let id = parseInt(x);
      if (!isNaN(id)) {
        let mq = (await this.dataRepositoryProxy.marketQuote(x))[0];
        let market = this.allMarkets.filter(
          (x) => x.id === mq?.marketId
        )[0];
        if (market && !this.markets.includes(market)) this.markets.push(market);
      }
    });
    console.debug("Markets: ", this.markets);
    this._updateContext(searchParameters, isStrict, undefined, undefined);
    await this._updateMarkets(this.markets);

    if (searchParameters) {
      let marketQuotes = [];
      this._updateMarketsWithRedisPrices(this.markets);
      this.localSetState();
      Promise.all(
        this.markets.map(async (x) => {
          let mqs = await this.dataRepositoryProxy.marketQuotes(x.id);
          x.marketQuotes = mqs;
          marketQuotes = marketQuotes.concat(mqs);
        })
      ).then(async () => {
        this.marketQuotes = marketQuotes;
        let initial = marketQuotes.filter(
          (x) => x.whiteLabelId === 0 && x.clientId === 0
        );
        let initialPrices = await this.redisProxy.priceLive(
          initial.map((x) => x.id)
        );
        initialPrices.forEach(async (x) => await this.onRedis(x));
        this.mqUpdate(initial);

        this.kafkaProxy.subscribeAndOverride(MarketHelpers.extractInstrumentNamesFromMarkets(this.markets));
        this.setState({ loading: false });
        this.localSetState();
      });
    }
    this.localSetState();
  };

  mqUpdate = (newMQs) => {
    if (this._isMounted) {
      console.debug("Selected MarketQuotes:", newMQs);
      this.redisProxy.subscribeAndOverride(newMQs);
    }
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  sort = (list, property) => {
    list.sort(function (a, b) {
      if (property(a) < property(b)) {
        return -1;
      }
      if (property(a) > property(b)) {
        return 1;
      }
      return 0;
    });
  };

  localSetState = () => {
    if (this._isMounted)
      this.setState((state) => ({
        markets: lodash.cloneDeep(this.markets),
      }));
  };

  onRedis = async (price) => {
    if (this._isMounted) {
      let found = this.marketQuotes?.filter(
        (y) => y.id === price.quoteId
      )[0];
      if (found) {
        let market = this.markets.filter(
          (x) => x.id === found.marketId
        )[0];
        if (market) {
          let mq = market.marketQuotes.filter((x) => x.id === price.quoteId)[0];
          if (mq) {
            let whiteLabel = this.state.whiteLabels.filter(
              (x) => x.id === mq.whiteLabelId
            )[0];
            mq.bid = price.bid;
            mq.ask = price.ask;
            mq.arrow = price.arrow;
            mq.dp = price.dp;
            mq.whiteLabelName = whiteLabel?.name;
            mq.low = price.low;
            mq.mid = price.mid;
            mq.high = price.high;
            mq.open = price.open;
            mq.close = price.close;
            mq.timestamp = price.timeString ? price.timeString : price.time;
            if (mq.whiteLabelId === 0 && mq.clientId === 0) {
              market.low = price.low;
              market.mid = price.mid;
              market.high = price.high;
              market.arrow = price.arrow;
              market.bid = price.bid;
              market.ask = price.ask;
              market.open = price.open;
              market.close = price.close;
              market.dp = price.dp;

              if (
                market.tradeable !== undefined &&
                market.tradeable !== price.tradeable
              )
                await this._updateMarkets([market]);

              market.tradeable = price.tradeable;
              market.timestamp = price.timeString
                ? price.timeString
                : price.time;
            }
          }
          this.localSetState();
        }
      }
    }
  };

  onKafkaPrice = (price) => {
    if (this._isMounted) {
      let all = this.markets?.filter((y) =>
        MarketHelpers.doesInstrumentNameEqual(y.prcGenRawSourceSymbol, price.internalId)
      );
      if (all)
        all.forEach((found) => {
          if (
            (found && found.status !== price.priceStatus) ||
            found.connector !== price.connectorName ||
            found.connectorInstance !== price.connectorInstanceId
          ) {
            found.priceStatus = price.priceStatusDescription;
            found.connector = price.connectorName;
            found.connectorInstance = price.connectorInstanceId;
            this.localSetState();
          }
        });
    }
  };

  onKafkaFeed = (feed) => {
    if (this._isMounted) {
      let all = this.markets?.filter((y) =>
        y.prcGenRawSourceSymbol.startsWith(feed.connectorName)
      );
      if (all)
        all.forEach((found) => {
          if (
            found &&
            found.connectorStatus !== feed.feedStatus &&
            found.connectorInstance === feed.connectorInstanceId
          ) {
            found.connectorStatus = feed.feedStatus;
            this.localSetState();
          }
        });
    }
  };

  onRedisReconnect = () => {
    if (this._isMounted) return this.marketQuotes?.map((x) => x.id);
    return [];
  };

  onKafkaReconnect = () => {
    if (this._isMounted) return this.state.markets?.map((x) => x.publishedName);
    return [];
  };

  async componentDidMount() {
    this._isMounted = true;
    let state = this.context.state;
    let selected = this.urlSearch.get("selected");
    let expanded = this.urlSearch.getAll("ex").map(x => decodeURIComponent(x));
    let names = this.urlSearch.getAll("name").map(x => decodeURIComponent(x));
    let operation = this.urlSearch.get("operation");
    let nameOrId = this.props.params.nameOrId
      ? decodeURIComponent(this.props.params.nameOrId).toLowerCase()
      : undefined;
    let searchParameters = nameOrId && nameOrId !== undefined ? [nameOrId] : names;
    this.setState({ search: searchParameters });

    await this.redisProxy.connect();
    await this.kafkaProxy.connect();
    await this.redisDbProxy.connect();
    await this.redisDbProxy.subscribeAndOverride();

    let whiteLabels = state.whiteLabels;
    if (!whiteLabels) {
      whiteLabels = await this.dataRepositoryProxy.whiteLabels();
      this.context.updateContext("whiteLabels", whiteLabels);
    }

    let markets = state.markets;
    if (!markets) {
      markets = await this.dataRepositoryProxy.markets();
      this.context.updateContext("markets", markets);
    }

    let marketGroups = state.marketGroups;
    if (!marketGroups) {
      marketGroups = await this.dataRepositoryProxy.marketGroups();
      this.context.updateContext("marketGroups", marketGroups);
    }

    let marketSuperGroups = state.marketSuperGroups;
    if (!marketSuperGroups) {
      marketSuperGroups = await this.dataRepositoryProxy.marketSuperGroups();
      this.context.updateContext("marketSuperGroups", marketSuperGroups);
    }

    let groupedBySuperGroup = this.groupBy(
      markets,
      (x) => x.marketSuperGroupId
    );
    let superGroups = [];
    groupedBySuperGroup.forEach((x) => {
      let children = [];
      let foundMarketSuperGroup = marketSuperGroups.filter(
        (y) => y.id === x[0].marketSuperGroupId
      )[0];
      let groupedByGroup = this.groupBy(x, (xx) => xx.marketGroupId);
      groupedByGroup.forEach((y) => {
        let foundMarketGroup = marketGroups.filter(
          (z) => z.id === y[0].marketGroupId
        )[0];
        children.push({
          title: foundMarketGroup.name.trim(),
          key: foundMarketGroup.id,
        });
      });
      this.sort(children, (x) => x.title);
      superGroups.push({
        title: foundMarketSuperGroup.name.trim(),
        key: foundMarketSuperGroup.name.trim(),
        icon: <CarryOutOutlined />,
        children: children,
      });
    });
    let mmSearch = state.mmSearch;

    console.debug("Search parameters:", searchParameters, mmSearch);

    this.sort(markets, (x) => x.name);
    this.sort(superGroups, (x) => x.title);

    this.allMarkets = markets;

    this.setState({ treeData: superGroups });
    this.setState({ whiteLabels: whiteLabels });
    this.setState({ marketSuperGroups: marketSuperGroups });
    this.setState({ platformsLoading: false });
    this.setState({ statusesLoading: false });
    this.setState({ riskGroupsLoading: false });
    this.setState({ regionsLoading: false });
    this.setState({ loading: false });

    if (selected)
      this.setState({ selectedKeys: [Number(selected)] });
    else if (mmSearch && searchParameters?.length === 0)
      this.setState({ selectedKeys: mmSearch?.selectedTree });

    if (expanded?.length > 0)
      this.setState({ expandedKeys: expanded });
    else if (mmSearch)
      this.setState({ expandedKeys: mmSearch?.expandedTree });

    if (operation)
      this.setState({ operationValue: operation });

    if (searchParameters.length > 0)
      this.onManualSelect(searchParameters, this._isEqualsSelected(operation));
    else if (mmSearch?.searchParameters.length > 0)
      this.onChange(null, null, mmSearch);
    else if (mmSearch?.selectedTree) this.onSelect(mmSearch.selectedTree);
    else if (selected) this.onSelect([Number(selected)]);
    this.updateExpandedUrl(mmSearch?.expandedTree);
    this.context.updateMenuSelection("marketMonitor");
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.kafkaProxy.stop();
    this.redisProxy.stop();
    this.redisDbProxy.stop();
  };

  onExpand = (expandedKeys, selectedKeys) => {
    if (!expandedKeys) {
      if (selectedKeys) {
        let element = selectedKeys[0];
        expandedKeys = !this.state.expandedKeys ? [] : this.state.expandedKeys;
        if (expandedKeys.includes(element))
          expandedKeys.splice(expandedKeys.indexOf(element), 1);
        else expandedKeys = expandedKeys.concat(selectedKeys);
      }
    }
    this.setState({ expandedKeys: [...expandedKeys] });
    this._updateContext(
      this.searchParameters,
      this.isStrict,
      this.selectedTree,
      expandedKeys
    );
    this.updateExpandedUrl(expandedKeys);
  };

  onMarketExpand = (expanded, record) => {
    if (!this.mqs)
      this.mqs = this.marketQuotes.filter(
        (x) => x.whiteLabelId === 0 && x.clientId === 0
      );

    if (expanded) {
      record.marketQuotes.forEach((x) => {
        if (x.prcGenSpreadByPercentage) {
          x.spread = x.prcGenSR + "%";
        } else {
          let now = DataHelpers.extractTime(null, true);
          let t2 = DataHelpers.extractTime(x.prcGenSwitchTime2);
          let t3 = DataHelpers.extractTime(x.prcGenSwitchTime3);
          let t4 = DataHelpers.extractTime(x.prcGenSwitchTime4);
          let t5 = DataHelpers.extractTime(x.prcGenSwitchTime5);

          x.spread = x.prcGenSpread;
          if (t2 && now.h >= t2.h && now.m >= t2.m && now.s >= t2.s)
            x.spread = x.prcGenSpread2;
          if (t3 && now.h >= t3.h && now.m >= t3.m && now.s >= t3.s)
            x.spread = x.prcGenSpread3;
          if (t4 && now.h >= t4.h && now.m >= t4.m && now.s >= t4.s)
            x.spread = x.prcGenSpread4;
          if (t5 && now.h >= t5.h && now.m >= t5.m && now.s >= t5.s)
            x.spread = x.prcGenSpread5;
        }
      });

      this.redisProxy
        .priceLive(record.marketQuotes.map((x) => x.id))
        .then((prices) => prices.forEach((x) => this.onRedis(x)));
      this.mqs = this.mqs.concat(
        record.marketQuotes.filter(
          (x) => x.whiteLabelId !== 0 || x.clientId !== 0
        )
      );
    } else {
      let toBeExcluded = record.marketQuotes
        .filter((x) => x.whiteLabelId !== 0 || x.clientId !== 0)
        .map((x) => x.id);
      this.mqs = this.mqs.filter((x) => !toBeExcluded.includes(x.id));
    }
    this.mqUpdate(this.mqs);
  };

  search = async () => {
    if (this._isMounted) {
      this.setState({ selectedKeys: [] });
      this._updateContext(undefined, undefined, [], undefined);

      if (this.state.search?.length > 0) {
        this.onManualSelect(this.state.search, this._isEqualsSelected());
      } else {
        await this.kafkaProxy.unsubscribe();
        await this.redisProxy.unsubscribe();
        this.markets = [];
        this.setState({ markets: [], loading: false });
      }
      this.updateUrl(this.state.search);
    }
  };

  onChange = async (event, text, mmSearch, searchParameters) => {
    let search = searchParameters
      ? searchParameters
      : text
        ? text
        : mmSearch?.searchParameters;
    this.setState({ loading: true });
    let name = event ? event.target.value.toLowerCase().split(',') : search;
    let names = event
      ? event.target.value
        .toLowerCase()
        .split(",")
        .map((x) => x.trim())
      : search;
    this.names = names;
    this.isStrict = event
      ? false
      : searchParameters
        ? true
        : mmSearch?.isStrict
          ? true
          : false;
    this.setState({ operationValue: this.isStrict ? "equals" : "contains" });
    this.setState({ search: name });
    this._updateContext(names, undefined, undefined, undefined);
    this.throttledSearch();
  };

  onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    console.debug(
      `Selected Market Ids: ${selectedRowKeys}`,
      "Selected Markets: ",
      selectedRows
    );
    this.setState({
      areAnyRowsSelected: selectedRows.length > 0,
      selectedRows: selectedRows,
    });
  };

  swap = async () => {
    this.setState({ loading: true });
    let uniq = _.uniq(this.state.selectedRows, (x) => x.prcGenRawSourceSymbol);
    console.debug("Selected Markets: ", uniq);
    Promise.all(
      uniq.map(async (x) => {
        let name = MarketHelpers.extractInstrumentNameFromMarket(x);
        return await this.pricingHubProxy.swapPrimaryAndSecondary(name);
      })
    ).then(() => {
      this.setState({ loading: false });
    });
  };

  onOperationChange = async (value) => {
    if (!value) value = "contains";
    this.operation = value;
    this.isStrict = value === "equals";
    this.setState({ operationValue: value });
    this.throttledSearch();
  };

  _updateMarketsWithRedisPrices = (markets) => {
    this.redisProxy
      .connectorPrices(MarketHelpers.extractInstrumentNamesFromMarkets(markets))
      .then(async (redisPrices) => {
        if (redisPrices) {
          let connectors = redisPrices?.map(
            (x) => x.connectorInstanceId + "." + x.connectorName
          );
          connectors = _.uniq(connectors);
          let feeds = await this.redisProxy.connectorStatusFeeds(connectors);
          redisPrices.forEach((x) => {
            for (const market in markets) {
              let feed = feeds?.filter(
                (y) =>
                  y.connectorName === x.connectorName &&
                  x.connectorInstanceId === y.connectorInstanceId
              )[0];
              if (
                MarketHelpers.doesInstrumentNameEqual(markets[market].prcGenRawSourceSymbol, x.internalId) ||
                MarketHelpers.doesInstrumentNameEqual(markets[market].prcGenRawSourceSymbolDecoded, x.internalId)
              ) {
                markets[market].priceStatus = x.priceStatusDescription;
                markets[market].connector = x.connectorName;
                markets[market].connectorInstance = x.connectorInstanceId;
                markets[market].connectorStatus = feed?.feedStatus;
              } else if (
                MarketHelpers.doesInstrumentNameEqual(markets[market].prcGenRawSourceSymbol2, x.internalId) ||
                MarketHelpers.doesInstrumentNameEqual(markets[market].prcGenRawSourceSymbolDecoded2, x.internalId)
              ) {
                markets[market].priceStatus2 = x.priceStatusDescription;
                markets[market].connector2 = x.connectorName;
                markets[market].connectorInstance2 = x.connectorInstanceId;
                markets[market].connectorStatus2 = feed?.feedStatus;
              }
            }
          });
          this.localSetState();
        }
      });
  };

  _updateContext = (searchParameters, isStrict, selectedTree, expandedTree) => {
    let mmSearch = this.context.state.mmSearch;
    this.context.updateContext("mmSearch", {
      searchParameters:
        searchParameters === undefined
          ? mmSearch
            ? mmSearch.searchParameters
            : []
          : searchParameters,
      isStrict:
        isStrict === undefined
          ? mmSearch
            ? mmSearch.isStrict
            : false
          : isStrict,
      selectedTree:
        selectedTree === undefined
          ? mmSearch
            ? mmSearch.selectedTree
            : []
          : selectedTree,
      expandedTree:
        expandedTree === undefined
          ? mmSearch
            ? mmSearch.expandedTree
            : []
          : expandedTree,
    });
  };

  _isEqualsSelected = (value) => value ? value === "equals" : this.state.operationValue === "equals";

  updateTradeOnWeb = async (id, tradeOnWeb) => {
    await this.dataRepositoryProxy.updateMarketTradeableParameters(
      id,
      !tradeOnWeb,
      null,
      null,
      null,
      null
    );
  };

  updateCallOnly = async (id, callOnly) => {
    await this.dataRepositoryProxy.updateMarketTradeableParameters(
      id,
      null,
      !callOnly,
      null,
      null,
      null
    );
  };

  updateAppearOnWeb = async (id, appearOnWeb) => {
    await this.dataRepositoryProxy.updateMarketTradeableParameters(
      id,
      null,
      null,
      !appearOnWeb,
      null,
      null
    );
  };

  updateLongPosition = async (id, flag) => {
    await this.dataRepositoryProxy.updateMarketTradeableParameters(
      id,
      null,
      null,
      null,
      !flag,
      null
    );
  };

  updateShortPosition = async (id, flag) => {
    await this.dataRepositoryProxy.updateMarketTradeableParameters(
      id,
      null,
      null,
      null,
      null,
      !flag
    );
  };

  updateUrlFromFilter(query, filter, queryProperty, title, defaultValue = null) {
    if ((!defaultValue && filter) || (defaultValue && filter !== defaultValue)) {
      query.set(queryProperty, filter);
    } else {
      query.delete(queryProperty);
    }
  }

  updateUrlFromFilterList = (
    query,
    filter,
    queryProperty,
    encode = false
  ) => {
    if (filter && filter.length > 0) {
      filter.map(x =>
        query.append(queryProperty, !encode ? x : encodeURIComponent(x))
      );
    }
    else {
      query.delete(queryProperty);
    }
  };

  updateUrl = (names, selected) => {
    const query = new URLSearchParams();
    console.debug("UpdateUrl: ", names, selected, this.state.expandedKeys, this.state.operationValue);
    if (names?.length === 1) {
      this.updateUrlFromFilterList(query, null, "name", "Name");
      this.updateUrlFromFilterList(query, this.state.expandedKeys, "ex", "Ex", true);
      this.updateUrlFromFilter(query, this.state.operationValue, "operation", "Operation", "contains");
      this.props.navigate(`/marketMonitor/${encodeURIComponent(names[0])}?${query.toString()}`, { replace: true });
    }
    else if (names?.length > 1) {
      this.updateUrlFromFilterList(query, names, "name", "Name", true);
      this.updateUrlFromFilterList(query, this.state.expandedKeys, "ex", "Ex", true);
      this.updateUrlFromFilter(query, this.state.operationValue, "operation", "Operation", "contains");
      this.props.navigate(`/marketMonitor?${query.toString()}`, { replace: true });
    }
    else {
      this.updateUrlFromFilterList(query, [], "name", "Name", true);
      this.updateUrlFromFilter(query, selected, "selected", "Selected");
      this.updateUrlFromFilterList(query, this.state.expandedKeys, "ex", "Ex", true);
      this.updateUrlFromFilter(query, this.state.operationValue, "operation", "Operation", "contains");
      this.props.navigate(`/marketMonitor?${query.toString()}`, { replace: true });
    }
  }

  updateExpandedUrl = (expanded) => {
    const query = new URLSearchParams();
    let originalString = this.props.location.search;
    console.debug("UpdateExpandedUrl: ", this.state.search, this.state.selectedKeys, expanded, this.state.operationValue);
    if (this.state.search.length > 1)
      this.updateUrlFromFilterList(query, this.state.search, "name", "name", true);
    this.updateUrlFromFilterList(query, this.state.selectedKeys, "selected", "Selected");
    this.updateUrlFromFilterList(query, expanded, "ex", "Ex", true);
    this.updateUrlFromFilter(query, this.state.operationValue, "operation", "Operation", "contains");
    if (query.toString().toLowerCase() !== originalString)
      this.props.navigate(this.props.location.pathname + "?" + query.toString());
  }

  render() {
    const { search, operationValue } = this.state;

    return (
      <Layout className="layout">
        <Sider>
          <Tree
            treeData={this.state.treeData}
            onSelect={this.onSelect}
            selectedKeys={this.state.selectedKeys}
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
          />
        </Sider>
        <Content style={{ padding: "0 5px" }}>
          <div>
            <Space style={{ margin: "0 0 5px 0" }}>
              <Title level={4}>Search</Title>
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="market name, description or id"
                onChange={this.onChange}
                style={{ width: 400 }}
                value={search}
              />
              <Select
                showSearch
                allowClear
                placeholder="search operations"
                onChange={this.onOperationChange}
                value={operationValue}
                style={{ width: 200 }}
              >
                <Option value="contains">Contains</Option>
                <Option value="equals">Equals</Option>
              </Select>
            </Space>
            <div className="site-layout-content">
              <Table
                rowKey="id"
                size="middle"
                loading={this.state.loading}
                columns={this.state.columns}
                dataSource={this.state.markets}
                onChange={this.handleChange}
                onExpand={this.onMarketExpand}
                expandedRowRender={(record, i) => (
                  <MarketDetails record={record} />
                )}
                expandedKeys={this.state.rowKeys}
              />
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}
MarketMonitor.contextType = AppContext;
export default Helpers.withParams(MarketMonitor);
