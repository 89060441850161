import React from "react";
import {
  Layout,
  Table,
  Typography,
  Space,
  Select,
  Row,
  Col,
  Input,
} from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { AppContext } from "../dataContext";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import LatencyChart from "./latencyChart";
import { Helpers } from "../helpers/helpers";
const { Content } = Layout;
const { Title } = Typography;

class Latencies extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;

    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy({
      dashUserWebsocketLatencies: this.dashUserWebsocketLatencies,
      onReconnect: this.onReconnect
    }
    );

    this._isMounted = false;
    this.options = [];
    this.state = {
      latencies: null,
      columns: [],
      filterColumns: {},
      notificationTypes: [],
      loading: false,
      sortOrder: "descend",
      options: [],
      whiteLabels: []
    };
  }

  async componentWillUnmount() {
    this._isMounted = false;
    await this.dynamicDataRepositoryDashProxy.stop();
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    await this._extractWhiteLabels();
    await this.dynamicDataRepositoryDashProxy.connect();
    await this.dynamicDataRepositoryDashProxy.subscribeAndOverride({});
    var columns = [
      {
        title: "Account (ClientId)",
        dataIndex: "clientId",
        render: (text, record) => record.userName ? `${record.userName} (${text})` : `- (${text})`
      },
      {
        title: "WhiteLabel",
        dataIndex: "whiteLabel",
        render: (text, record) => text ? text : `-`
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => text ? text : `-`
      },
      {
        title: "User Name",
        dataIndex: "userName",
        render: (text, record) => text ? text : `-`
      },
      {
        title: "Number of connections",
        dataIndex: "numberOfConnections",
      },
      {
        title: "Avg. roundtrip [ms]",
        dataIndex: "avgRoundtrip",
      },
    ];
    console.debug("Setting columns");
    if (this._isMounted) this.setState({ columns: columns });

    this.context.updateMenuSelection("latencies");
  }

  dashUserWebsocketLatencies = (message) => {
    if (this._isMounted) {
      this.setState(
        {
          latencies: message,
          loading: false
        }
      );
    }
  }

  onSearchChange = async (event) => {
    this.setState({ loading: true });
    this.names = [];
    this.searchTags = [];
    var search = event.target.value;
    this.page = 1;
    var items = search.split(",").map(x => x.replace('"', '').replace('"', '').trim().toLowerCase()).filter(x => x && x !== "");
    if (items?.length > 0) {
      this.setState({ search: search, convertedSearch: items }, async () => await this.dynamicDataRepositoryDashProxy.subscribeAndOverride({ clients: items, whiteLabel: this.state.whiteLabelId }));
    }
    else
      this.setState({ search: search, convertedSearch: items }, async () => await this.dynamicDataRepositoryDashProxy.subscribeAndOverride({ clients: [], whiteLabel: this.state.whiteLabelId }));
  };

  onReconnect = () => {
    return { clients: this.state.convertedSearch, whiteLabel: this.state.whiteLabelId };
  }

  onWhiteLabelChange = async (value, item) => {
    this.setState({ loading: true });
    console.debug(value, item);
    var id = item ? item.key : null;
    this.setState({ whiteLabel: value, whiteLabelId: id, search: "", convertedSearch: [] }, async () => await this.dynamicDataRepositoryDashProxy.subscribeAndOverride({ clients: this.state.convertedSearch, whiteLabel: id }));
  };

  _extractWhiteLabels = async () => {
    var whiteLabels = await this.dynamicDataRepositoryDashProxy.whiteLabels();
    var convertedWhiteLabels = [];
    whiteLabels.forEach((x) => {
      convertedWhiteLabels.push({ value: x.name, key: x.id });
      convertedWhiteLabels = convertedWhiteLabels.sort((x, y) =>
        x.value.localeCompare(y.value)
      );
    });
    this.setState({ whiteLabels: [...convertedWhiteLabels] });
    return convertedWhiteLabels;
  };

  render() {
    const { search, whiteLabels, whiteLabel } = this.state;
    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Space style={{ margin: "0 0 5px 0" }}>
            <Title level={4}>Latencies</Title>
          </Space>
          <Row gutter={[16, 16]}>
            <Col>
              <Title level={4}>Search</Title>
            </Col>
            <Col flex="4">
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="clientId, name, user name"
                onChange={this.onSearchChange}
                value={search}
                disabled={whiteLabel ? true : false}
              />
            </Col>
            <Col flex="2">
              <Select
                showSearch
                allowClear
                placeholder="white labels"
                onChange={this.onWhiteLabelChange}
                options={whiteLabels}
                value={whiteLabel}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <br />
          <div className="site-layout-content">
            <Table
              rowKey="clientId"
              loading={this.state.loading}
              dataSource={this.state.latencies}
              pagination={{ showSizeChanger: false }}
              columns={this.state.columns}
              expandedRowRender={(record, i) => (
                <LatencyChart record={record} />
              )}
            />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Helpers.withParams(Latencies);
