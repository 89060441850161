import React, { Component } from "react";
import { Layout, Input, Typography, Space, Select, Col, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as _ from "underscore";
import "antd/dist/antd.dark.css";
import "../main.css";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";
import DisabledProviderInstrumentsTable from "./disabledProviderInstrumentsTable";
const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

class DisabledProviderInstruments extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.urlSearch = new URLSearchParams(props.location.search);

    this.throttledSearch = _.throttle(this.search, 1000);

    this.pricingHubProxy = new ConnectorsCoordinatorProxy();

    this.props = props;
    this.state = {
      loading: undefined,
      search: undefined,
      connectedToInstrument: undefined,
      disabledProviderInstruments: [],
      toBeDisabledCurrentFilterValues: [],
    };
  }

  search = async () => {
    let result = undefined;
    this.setState({ loading: true });
    if (this._isMounted) {
      if (
        this.state.search?.length > 1 ||
        this.state.toBeDisabledCurrentFilterValues?.length > 0 ||
        this.state.connectedToInstrument
      ) {
        result = await this.pricingHubProxy.getDisabledProviderInstruments(
          this.state.search?.trim(),
          this.state.toBeDisabledCurrentFilterValues,
          this.state.connectedToInstrument
        );
      } else {
        result = await this.pricingHubProxy.getDisabledProviderInstruments();
      }
      this.setState({
        disabledProviderInstruments: result.disabledProviderInstruments,
      });
    }
    this.updateUrl(
      this.state.search,
      this.state.toBeDisabledCurrentFilterValues
    );
    this.setState({ loading: false });
  };

  onChange = async (event, providerInstrumentSearch) => {
    var search = providerInstrumentSearch?.search;
    search = event ? event.target.value : "";
    this.setState({ search: search }, this.throttledSearch);
  };

  onConnectedToInstrumentChange = async (event, value) => {
    let connectedToInstrument = event.target.checked;
    this.setState(
      { connectedToInstrument: connectedToInstrument },
      this.throttledSearch
    );
  };

  async componentDidMount() {
    this.context.updateMenuSelection("disabledProviderInstruments");

    this._isMounted = true;
    this.throttledSearch();
    document.title = "Disabled Provider Instruments";
  }

  componentDidUpdate = (prevProps) => {};

  componentWillUnmount = async () => {
    this._isMounted = false;
  };

  pageChanged = async (pageData) => {
    this.setState({ currentPage: pageData.current });
    this.page = pageData.current;
    this.throttledSearch();
  };

  onDisabledFilterChanged = (e, control) => {
    if (e) {
      this.setState(
        { toBeDisabledCurrentFilterValues: e },
        this.throttledSearch
      );
    } else {
      this.setState(
        { toBeDisabledCurrentFilterValues: [] },
        this.throttledSearch
      );
    }
    if (control) {
      control.blur();
    }
  };

  updateUrlFromFilter(query, filter, queryProperty, title) {
    if (filter) {
      query.set(queryProperty, filter);
    } else {
      query.delete(queryProperty);
    }
  }

  updateUrl = (search, toBeDisabledEnum) => {
    search = search?.trim();
    let connectedToInstrument = this.state.connectedToInstrument;
    const baseURL = "/disabledproviderinstruments";
    let params = [];
    if (search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (toBeDisabledEnum?.length > 0) {
      params.push(`toBeDisabled=${encodeURIComponent(toBeDisabledEnum)}`);
    }
    if (connectedToInstrument) {
      params.push(
        `connectedToInstrument=${encodeURIComponent(connectedToInstrument)}`
      );
    }
    const queryString = params.join("&");
    const url = `${baseURL}${queryString ? `?${queryString}` : ""}`;

    this.props.navigate(url);
  };

  filterDropDown = (input, option) => {
    return (
      option.props &&
      ((option.props.value &&
        (option.props.value === input ||
          (Object.prototype.toString.call(option.props.value) ===
            "[object String]" &&
            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
              0))) ||
        (option.props.key &&
          option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
        (option.props.children &&
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0))
    );
  };

  render() {
    var that = this;
    const toBeDisabledOptions = [
      { key: 0, value: "None" },
      { key: 1, value: "Day 1" },
      { key: 2, value: "Day 2" },
      { key: 3, value: "Day 3" },
      { key: 4, value: "Disabled" },
    ];

    const toBeDisabledFilter = (
      <Select
        mode="multiple"
        allowClear
        style={{ width: "350px" }}
        placeholder="Please select"
        value={this.state.toBeDisabledCurrentFilterValues}
        onChange={(e) =>
          that.onDisabledFilterChanged(e, that.toBeDisabledFilter)
        }
        showArrow={true}
        ref={(select) => (that.toBeDisabledFilter = select)}
        filterOption={(input, option) => this.filterDropDown(input, option)}
      >
        {toBeDisabledOptions.map((element) => (
          <Option
            value={toBeDisabledOptions.findIndex((x) => x.key === element.key)}
            key={toBeDisabledOptions.findIndex((x) => x.key === element.key)}
          >
            {element.value}
          </Option>
        ))}
      </Select>
    );

    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px " }}>
          <Space style={{ padding: "0px 0px 35px 0px" }} wrap>
            <Space style={{ margin: "0 15px 0 0" }}>
              <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                Search
              </Title>
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="PI/Instrument Name, Description or ID"
                style={{ width: 400 }}
                onChange={this.onChange}
              />
            </Space>
            <Col flex="2">
              <Space style={{ margin: "0 15px 0 0" }}>
                <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                  Provider disabled state:
                </Title>
                {toBeDisabledFilter}
              </Space>
            </Col>
            <Col flex="2">
              <Space style={{ margin: "0 15px 0 0" }}>
                <Title style={{ margin: "0px 0px 0px 0px" }} level={5}>
                  Connected to instrument only:
                </Title>
                <Checkbox
                  onChange={this.onConnectedToInstrumentChange}
                ></Checkbox>
              </Space>
            </Col>
          </Space>
          <div className="disabledProviderInstrumentTable">
            <DisabledProviderInstrumentsTable
              loading={this.state.loading}
              disabledProviderInstruments={
                this.state.disabledProviderInstruments
              }
            />
          </div>
        </Content>
      </Layout>
    );
  }
}

DisabledProviderInstruments.contextType = AppContext;
export default Helpers.withParams(DisabledProviderInstruments);
