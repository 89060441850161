export class DataHelpers {
  
  // used by pricing hub proxy
  static convert = (instrumentWithFeeds) => {
    var primaryPI = instrumentWithFeeds.primaryFeed.providerInstrument
    var secondaryPI = instrumentWithFeeds.secondaryFeed?.providerInstrument;

    var primaryConnectorMap = instrumentWithFeeds.primaryFeed.connectorMapDatabaseEntity
    var secondaryConnectorMap = instrumentWithFeeds.secondaryFeed?.connectorMapDatabaseEntity;

    var converted = {
      id: instrumentWithFeeds.instrument.id,
      enabled: instrumentWithFeeds.instrument.enabled,
      publishedName: instrumentWithFeeds.instrument.publishedName,
      publishedSource: instrumentWithFeeds.instrument.publishedSource,
      description: instrumentWithFeeds.instrument.description,
      mt4: instrumentWithFeeds.instrument.mt4Name ? true : false,
      mt4Name: instrumentWithFeeds.instrument.mt4Name,
      timeoutFailover: instrumentWithFeeds.instrument.instrumentTimeoutFailover,
      timeoutInSec: instrumentWithFeeds.instrument.instrumentTimeoutInSec,
      timeoutInSecAtNight: instrumentWithFeeds.instrument.instrumentTimeoutInSecAtNight,
      backwardationFix: instrumentWithFeeds.instrument.backwardationFix,
      zeroPriceFilter: instrumentWithFeeds.instrument.zeroPriceFilter,
      maxChangeMovementValue: instrumentWithFeeds.instrument.maxChangeMovementValue,
      maxChangeNumberOfPrices: instrumentWithFeeds.instrument.maxChangeNumberOfPrices,
      maxChangeDiscard: instrumentWithFeeds.instrument.maxChangeDiscard,
      providerName: instrumentWithFeeds.primaryFeed.provider.name,
      tags: instrumentWithFeeds.instrument.tags,
      dp: instrumentWithFeeds.instrument.decimalPlaces,
      isTest : instrumentWithFeeds.instrument.isTest,

      primaryConnectorMapId: primaryConnectorMap?.id,
      primaryId: primaryPI?.id,
      primaryName: primaryPI?.name,
      primaryFeed: instrumentWithFeeds.primaryFeed.provider.name,
      primaryEnabled: primaryPI?.enabled,
      primaryIsTest: primaryPI?.isTest,
      primaryStatus: primaryConnectorMap?.status,
      primaryStatusDescription: this.convertPriceStatus(primaryConnectorMap?.status),
      primaryActivityStatus: primaryConnectorMap?.activityStatus,
      primaryActivityStatusDescription: primaryConnectorMap?.activityStatus,
      primaryFairValue: primaryConnectorMap?.fairValue,
      primaryFeedConnectorInstance: instrumentWithFeeds.primaryFeed.provider.isAActive ? "A" : "B",

      secondaryConnectorMapId: secondaryConnectorMap?.id,
      secondaryId: secondaryPI?.id,
      secondaryName: secondaryPI?.name,
      secondaryFeed: instrumentWithFeeds.secondaryFeed?.provider.name,
      secondaryEnabled: secondaryPI?.enabled,
      secondaryIsTest: secondaryPI?.isTest,
      secondaryStatus: secondaryConnectorMap?.status,
      secondaryStatusDescription: this.convertPriceStatus(secondaryConnectorMap?.status),
      secondaryActivityStatus: secondaryConnectorMap?.activityStatus,
      secondaryActivityStatusDescription: secondaryConnectorMap?.activityStatus,
      secondaryFairValue: secondaryConnectorMap?.fairValue,
      secondaryFeedConnectorInstance: instrumentWithFeeds.secondaryFeed?.provider.isAActive ? "A" : "B",

      isPrimaryActive: primaryConnectorMap?.activityStatus !== 1,
      constituentPrices: [],
    };
    converted.constituentPrices.push({
      name: converted.primaryName,
      feed: converted.primaryFeed,
      isActive: converted.isPrimaryActive
    });
    if (converted.secondaryConnectorMapId)
      converted.constituentPrices.push({
        name: converted.secondaryName,
        feed: converted.secondaryFeed,
        isActive : !converted.isPrimaryActive
      });
    return converted;
  };

  static convertPriceStatus(priceStatusNumber) {
    if (priceStatusNumber === undefined) {
      return null;
    }
  
    switch (priceStatusNumber) {
      case 1:
        return "Normal";
      case 2:
        return "Timed out";
      case 3:
        return "Error";
      case 4:
        return "Indicative";
      case 5:
        return "Data not available";
      case 6:
        return "Not connected";
      case 7:
        return "Validation error";
      case 8:
        return "Unsubscribed";
      case 9:
        return "Trading halt";
      default:
        return "Unknown";
    }
  }

  // used by dash proxy
  static extractInstrumentNamesFromConnectorMaps(connectorMaps) {
    return connectorMaps.map((x) => x.publishedName);
  }

  // used by market monitor
  static extractTime(dateTime, isNow = false) {
    if (isNow || dateTime) {
      var time = dateTime ? new Date(dateTime) : new Date();
      var h = dateTime ? time.getHours() : time.getUTCHours();
      var m = dateTime ? time.getMinutes() : time.getUTCMinutes();
      var s = dateTime ? time.getSeconds() : time.getUTCSeconds();
      return { h, m, s };
    }
    return undefined;
  }
}
