import React, { Component } from "react";
import {
  Layout,
  Button,
  Space,
  Spin,
  Upload,
  List,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import { InboxOutlined, FileOutlined } from "@ant-design/icons";
import "antd/dist/antd.dark.css";
import "../main.css";
import { AppContext } from "../dataContext";
import { Helpers } from "../helpers/helpers";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";

const { Content } = Layout;

class ProviderInstrumentImport extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();
    const coordinatorAvailable = Helpers.currentEnv().COORDINATOR_AVAILABLE === "true";
    this.props = props;
    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy(
      {}
    );
    this.pricingHubProxy = new ConnectorsCoordinatorProxy();

    this.state = {
      loading: true,
      coordinatorAvailable: coordinatorAvailable,
      fileList: [],
      uploadingProviderInstrumentDisabled: true,
      uploadingInstrumentDisabled: true,
      selectedProvider: "",
      disabledImport: true,
      providerDisabled: false,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.context.updateMenuSelection("importProviderInstruments");
    let providers = await this.dynamicDataRepositoryDashProxy.providers();
    providers = providers.map((x) => {
      return { label: x.name, value: x.name };
    });
    this.setState({ loading: false, providers: providers });
  };

  componentDidUpdate = async (props) => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  uploadProviderInstrumentFile = async () => {
    this.setState({ loading: true });
    let file = this.state.providerInstrumentFileList[0];
    let response = await this.pricingHubProxy.importProviderInstruments(
      this.state.selectedProvider,
      file
    );
    if (response?.errors.length === 0)
      Helpers.showInfo(
        `${file.name.toUpperCase()} has been imported successfully`
      );
    else {
      this.setState({ uploadingProviderInstrumentDisabled: true });
      let error = response?.errors.join(", ");
      Helpers.showError(`${error}`);
    }
    this.setState({
      loading: false,
      providerDisabled: false,
      uploadingProviderInstrumentDisabled: true,
    });
  };

  uploadInstrumentFile = async () => {
    this.setState({ loading: true });
    let file = this.state.instrumentFileList[0];
    let response = await this.pricingHubProxy.importInstruments(file);
    if (response?.errors.length === 0)
      Helpers.showInfo(
        `${file.name.toUpperCase()} has been imported successfully`
      );
    else {
      this.setState({ uploadingInstrumentDisabled: true });
      let error = response?.errors.join(", ");
      Helpers.showError(`${error}`);
    }
    this.setState({ loading: false });
  };

  validateProviderInstrumentFile = async (options) => {
    const { onSuccess, onError, file } = options;
    let response = await this.pricingHubProxy.validateProviderInstrumentsImport(
      this.state.selectedProvider,
      file
    );
    if (!response || !response.errors) {
      onError(
        new Error("Problems with reaching the service. Please try again.")
      );
      this.setState({
        providerInstrumentErrors:
          "Problems with reaching the service. Please try again.",
      });
    } else if (response?.errors?.length > 0) {
      onError(new Error(response?.errors.join(", ")));
      this.setState({
        providerInstrumentErrors: response?.errors.join(", "),
        providerInstrumentErrorContent: response?.results,
      });
    } else {
      this.setState({
        providerInstrumentErrors: "Ready to be imported!",
        providerInstrumentErrorContent: response.results,
        uploadingProviderInstrumentDisabled: !this.state.selectedProvider,
      });
      onSuccess("Valid");
    }
  };

  validateInstrumentFile = async (options) => {
    const { onSuccess, onError, file } = options;
    let response = await this.pricingHubProxy.validateInstrumentsImport(file);
    if (!response || !response.errors) {
      onError(
        new Error("Problems with reaching the service. Please try again.")
      );
      this.setState({
        instrumentErrors:
          "Problems with reaching the service. Please try again.",
        uploadingInstrumentDisabled: true,
      });
    } else if (response?.errors?.length > 0) {
      onError(new Error(response?.errors.join(", ")));
      this.setState({
        instrumentErrors: response?.errors.join(", "),
        instrumentErrorContent: response?.results,
        uploadingInstrumentDisabled: true,
      });
    } else {
      this.setState({
        instrumentErrors: "Ready to be imported!",
        instrumentErrorContent: response.results,
        uploadingInstrumentDisabled: false,
      });
      onSuccess("Valid");
    }
  };

  onChange = (info) => {
    console.debug(info.fileList);
  };

  onProviderInstrumentFileRemove = (file) => {
    this.setState((state) => {
      const index = state.providerInstrumentFileList.indexOf(file);
      const newFileList = state.providerInstrumentFileList.slice();
      newFileList.splice(index, 1);
      return {
        providerInstrumentFileList: newFileList,
      };
    });
    this.setState({
      providerInstrumentErrors: "",
      providerInstrumentErrorContent: [],
      uploadingProviderInstrumentDisabled: true,
      providerDisabled: false,
    });
  };

  onInstrumentFileRemove = (file) => {
    this.setState((state) => {
      const index = state.instrumentFileList.indexOf(file);
      const newFileList = state.instrumentFileList.slice();
      newFileList.splice(index, 1);
      return {
        instrumentFileList: newFileList,
      };
    });
    this.setState({
      instrumentErrors: "",
      instrumentErrorContent: [],
      uploadingInstrumentDisabled: true,
    });
  };

  beforeProviderInstrumentUpload = (file) => {
    this.setState({
      providerInstrumentErrors: "",
      providerInstrumentErrorContent: [],
      uploadingDisabled: true,
      providerDisabled: true,
    });
    let isCSV = file.type === "text/csv" && file.name.endsWith(".csv");
    if (!isCSV) {
      Helpers.showError(`${file.name.toUpperCase()} is not a CSV file`);
      this.setState((state) => ({
        providerInstrumentFileList: [],
      }));
    } else
      this.setState((state) => ({
        providerInstrumentFileList: [file],
      }));
    return isCSV ? true : Upload.LIST_IGNORE;
  };

  beforeInstrumentUpload = (file) => {
    this.setState({
      instrumentErrors: "",
      instrumentErrorContent: [],
      uploadingDisabled: true,
    });
    let isCSV = file.type === "text/csv" && file.name.endsWith(".csv");
    if (!isCSV) {
      Helpers.showError(`${file.name.toUpperCase()} is not a CSV file`);
      this.setState((state) => ({
        instrumentFileList: [],
      }));
    } else
      this.setState((state) => ({
        instrumentFileList: [file],
      }));
    return isCSV ? true : Upload.LIST_IGNORE;
  };

  onProviderChange = (value) => {
    let disabled = this.state.errors || this.state.fileList.length === 0;
    this.setState({
      selectedProvider: value,
      uploadingDisabled: disabled,
      disabledImport: false,
    });
  };

  render() {
    return (
      <Content style={{ padding: "0 5px" }}>
        <Spin spinning={this.state.loading}>
          <Row
            gutter={[16, 16]}
            align="top"
            disabled={this.state.disabledImport}
          >
            <Col span={12}>
              <Space>
                Provider Instruments{" "}
                <Tooltip title="Import template">
                  <a href="providerInstrumentsImportTemplate.csv">
                    <FileOutlined />
                  </a>
                </Tooltip>
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                Instruments{" "}
                <Tooltip title="Import template">
                  <a href="instrumentsImportTemplate.csv">
                    <FileOutlined />
                  </a>
                </Tooltip>
                <Tooltip title="Update template">
                  <a href="instrumentsUpdateTemplate.csv">
                    <FileOutlined />
                  </a>
                </Tooltip>
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                Providers:
                <Select
                  disabled={this.state.providerDisabled}
                  options={this.state.providers}
                  style={{ width: 200 }}
                  placeholder="Select a provider"
                  onChange={this.onProviderChange}
                ></Select>
              </Space>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Upload.Dragger
                disabled={this.state.disabledImport}
                maxCount={1}
                onChange={this.onChange}
                beforeUpload={this.beforeProviderInstrumentUpload}
                customRequest={this.validateProviderInstrumentFile}
                onRemove={this.onProviderInstrumentFileRemove}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag a CSV file to this area to upload Provider
                  Instruments
                </p>
                <p className="ant-upload-hint">
                  All the required columns are listed in the template files
                  above.
                </p>
              </Upload.Dragger>
            </Col>
            <Col span={12}>
              <Upload.Dragger
                maxCount={1}
                onChange={this.onChange}
                beforeUpload={this.beforeInstrumentUpload}
                customRequest={this.validateInstrumentFile}
                onRemove={this.onInstrumentFileRemove}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag a CSV file to this area to upload Instruments
                </p>
                <p className="ant-upload-hint">
                  All the required columns are listed in the template files
                  above.
                </p>
              </Upload.Dragger>
            </Col>
            <Col span={12}>
              <p>
                Validation results: <b>{this.state.providerInstrumentErrors}</b>
              </p>
              <List
                split={false}
                itemLayout="horizontal"
                dataSource={this.state.providerInstrumentErrorContent}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.split("|")[0]}
                    />
                  </List.Item>
                )}
              />
              <Tooltip
                title={
                  !this.state.coordinatorAvailable
                    ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                    : ""
                }
                color="red"
              >
                <Button
                  type="primary"
                  onClick={this.uploadProviderInstrumentFile}
                  disabled={
                    this.state.uploadingProviderInstrumentDisabled ||
                    !this.state.coordinatorAvailable
                  }
                  loading={this.uploading}
                  style={{ marginTop: 16 }}
                >
                  {this.uploading ? "Uploading" : "Start Upload"}
                </Button>
              </Tooltip>
            </Col>
            <Col span={12}>
              <p>
                Validation results: <b>{this.state.instrumentErrors}</b>
              </p>
              <List
                split={false}
                itemLayout="horizontal"
                dataSource={this.state.instrumentErrorContent}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.split("|")[0]}
                    />
                  </List.Item>
                )}
              />
              <Tooltip
                title={
                  !this.state.coordinatorAvailable
                    ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                    : ""
                }
                color="red"
              >
                <Button
                  type="primary"
                  onClick={this.uploadInstrumentFile}
                  disabled={
                    this.state.uploadingInstrumentDisabled ||
                    !this.state.coordinatorAvailable
                  }
                  loading={this.uploading}
                  style={{ marginTop: 16 }}
                >
                  {this.uploading ? "Uploading" : "Start Upload"}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Spin>
      </Content>
    );
  }
}

ProviderInstrumentImport.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentImport);
