import { Component } from "react";

export class KafkaComponent extends Component {
  constructor(props) {
    super();
  }

  _onPrice = (price, trigger) => {
    var all = this.instruments?.filter(
      (y) => y.publishedName.toLowerCase() === price.internalId.toLowerCase()
    );
    if (all)
      all.forEach((instrument) => {
        instrument.timestamp = price.time;
        instrument.arrow =
          instrument.bid < price.bid ? "u" : instrument.ask === price.bid ? "e" : "d";
        if ((instrument.primaryName.toLowerCase() === price.externalId.toLowerCase() && instrument.primaryActivityStatus === 2) ||
          (instrument.secondaryName?.toLowerCase() === price.externalId.toLowerCase() && instrument.secondaryActivityStatus === 2)) {
          instrument.bid = price.bid;
          instrument.ask = price.ask;
        }
        if (instrument.primaryName.toLowerCase() === price.externalId.toLowerCase()) {
          instrument.primaryStatus = price.priceStatus;
          instrument.primaryStatusDescription = price.priceStatusDescription;
        } else if (instrument.secondaryName?.toLowerCase() === price.externalId.toLowerCase()) {
          instrument.secondaryStatus = price.priceStatus;
          instrument.secondaryStatusDescription = price.priceStatusDescription;
        }
        instrument.isPrimaryActive = instrument.primaryName === price.externalId && instrument.primaryActivityStatus === 2;

        var found = instrument.constituentPrices.filter(x => x.name.toLowerCase() === price.externalId.toLowerCase() 
        && x.feed.toLowerCase() === price.connectorName.toLowerCase())[0];
        if (found) {
          found.bid = price.bid;
          found.ask = price.ask;
          found.timestamp = price.time;
          found.isActive = true
        }
        trigger();
      });
  };

  _onInactivePrice = (price, trigger) => {
    var all = this.instruments?.filter(
      (y) => y.publishedName.toLowerCase() === price.internalId.toLowerCase()
    );
    if (all)
      all.forEach((item) => {
        if (!item.constituentPrices)
          item.constituentPrices = [];

        var found = item.constituentPrices.filter(x => x.name.toLowerCase() === price.externalId.toLowerCase() 
        && x.feed.toLowerCase() === price.connectorName.toLowerCase())[0];
        if (found) {
          found.bid = price.bid;
          found.ask = price.ask;
          found.timestamp = price.time;
          found.isActive = false
        }
        trigger();
      });
  };

  _onPriceStatus = (price, trigger) => {
    var all = this.instruments?.filter(
      (y) => y.publishedName.toLowerCase() === price.internalId.toLowerCase()
    );
    if (all)
      all.forEach((item) => {
        if (item.primaryName.toLowerCase() === price.externalId.toLowerCase()) {
          item.primaryStatus = price.priceStatusEnum;
          item.primaryStatusDescription = price.priceStatus;
        } else if (item.secondaryName.toLowerCase() === price.externalId.toLowerCase()) {
          item.secondaryStatus = price.priceStatusEnum;
          item.secondaryStatusDescription = price.priceStatus;
        }
        trigger();
      });
  };

  _onReconnect = () => {
    return this.instruments?.map((x) => x.publishedName);
  };
}
export default KafkaComponent;
