import React, { Component } from "react";
import {
  Layout,
  Input,
  Typography,
  Space,
  Button,
  Tooltip,
  Switch,
  Col,
  Row,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import * as _ from "underscore";

import "antd/dist/antd.dark.css";
import "../main.css";
import DynamicDataRepositoryRedisDbProxy from "../proxies/redisDbProxy";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import { Helpers } from "../helpers/helpers";
import { ProviderInstrumentHelper } from "./helpers/providerInstrumentHelper";
import { AppContext } from "../dataContext";
import ProviderInstrumentTable from "./providerInstrumentTable";

const { Content } = Layout;
const { Title } = Typography;

class ProviderInstrumentSearch extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.urlSearch = new URLSearchParams(props.location.search);

    this.throttledSearch = _.throttle(this.search, 1000, {
      loading: false,
    });

    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy(
      {}
    );
    this.pricingHubProxy = new ConnectorsCoordinatorProxy();
    this.redisDbProxy = new DynamicDataRepositoryRedisDbProxy({
      onProviderInstrument: this.onProviderInstrument,
    });
    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      search: undefined,
      id: this.props.params.id,
      providerInstruments: [],
      providers: [],
    };
  }

  onProviderInstrument = (payload) => {
    let providerInstrument = payload.data;
    let foundProviderInstrument = this.state.providerInstruments?.filter(
      (y) => y.id === providerInstrument.id
    )[0];
    if (foundProviderInstrument) {
      foundProviderInstrument.editingDisabled = false;
      ProviderInstrumentHelper.mergeProviderInstrument(
        foundProviderInstrument,
        providerInstrument
      );
      this.throttledSearch();
    }
  };

  search = async () => {
    let result = undefined;
    if (this._isMounted) {
      this.oldProviderName = this.providerName;
      this.oldName = this.name;
      result = await this.pricingHubProxy.providerInstrumentsSearch(
        this.providerName?.replace("#", "").toLowerCase(),
        this.name?.trim(),
        this.page,
        this.showTest
      );
      Helpers.sort(result.providerInstruments, (x) => x.name);
      let usedProviderInstrumentIds =
      await this.pricingHubProxy.identifyUsedProviderInstruments(
        result.providerInstruments.map((x) => x.id)
      );
    result.providerInstruments.forEach((x) => {
      x.used = usedProviderInstrumentIds.filter((y) => y === x.id)[0]
        ? true
        : false;
    });
      this.setState({
        providerInstruments: result.providerInstruments,
        total: result.counter,
      });
    }

    this.updateUrl(this.providerName, this.name, this.state.showTest);

    if (!result?.cancelled) this.setState({ loading: false });
  };

  onChange = async (
    event,
    providerInstrumentSearch,
    instrumentName,
    providerName,
    showTest
  ) => {
    this.providerName = providerName;
    let name = instrumentName;
    var search = providerInstrumentSearch?.search;
    this.showTest = showTest;
    if (event || instrumentName || providerName) {
      this.showTest = this.showTest ?? this.state.showTest;
      search = event
        ? event.target.value
        : (providerName ? "#" + providerName.trim() + " " : "") +
          (instrumentName ? instrumentName.trim() : "");
    }
    if (search && !providerName && !instrumentName) {
      this.providerName = undefined;
      this.showTest = this.showTest ?? providerInstrumentSearch?.showTest;
      search.split(" ").forEach((x) => {
        if (x.includes("#"))
          this.providerName = x.replace("#", "").toLowerCase();
        else name = (name ? name : "") + " " + x.toLowerCase();
      });
    }
    let foundProvider = this.state.providers.filter(
      (x) => x.name.toLowerCase() === this.providerName?.trim()
    )[0];
    this.setState({ search: search, showTest: this.showTest });
    this.context.state.providerInstrumentSearch = {
      search: search,
      showTest: this.showTest,
    };
    if (name?.length > 2 || foundProvider) {
      this.setState({ loading: true });
      if (name?.length > 2) this.name = name;
      else this.name = undefined;
      this.page = 1;
      this.setState({ currentPage: 1 });
      this.throttledSearch();
    } else {
      this.setState({ providerInstruments: [], currentPage: 1, total: 0 });
      this.updateUrl(null, null, this.showTest);
    }
  };

  addNew = () => {
    this.context.showProviderInstrumentAdd();
  };

  loaded = async () => {
    let providers = await this.dynamicDataRepositoryDashProxy.providers();
    let state = this.context.state;
    this.setState(
      {
        providers: providers,
      },
      async () => {
        if (
          state.providerInstrumentSearch ||
          this.props.params.id ||
          this.props.params.name
        ) {
          await this.onChange(
            undefined,
            state.providerInstrumentSearch,
            this.props.params.id
              ? decodeURIComponent(this.props.params.id).toLowerCase()
              : undefined,
            this.props.params.name
              ? this.props.params.name.toLowerCase()
              : undefined
          );
        }
      }
    );
    this.context.updateMenuSelection("allProviderInstruments");
  };

  componentDidMount = async () => {
    this._isMounted = true;
    await this.redisDbProxy.connect();
    await this.redisDbProxy.subscribeAndOverride();
    let state = this.context.state;

    var isTest = this.urlSearch.get("isTest");
    this.setState(
      { showTest: isTest === "true" },
      async () => await this.loaded()
    );

    console.debug(
      "MOUNT...",
      this.props.params.id,
      this.props.params.name,
      state.isLoadedAndReady
    );
  };

  componentDidUpdate = (prevProps) => {};

  componentWillUnmount = async () => {
    this._isMounted = false;
    await this.redisDbProxy.stop();
  };

  pageChanged = async (pageData) => {
    this.setState({ currentPage: pageData.current, loading: true });
    this.page = pageData.current;
    this.throttledSearch();
  };

  onShowTestChange = (checked, e) => {
    let state = this.context.state;
    this.setState({ showTest: checked }, async function () {
      await this.onChange(
        undefined,
        state.providerInstrumentSearch,
        this.props.params.id
          ? decodeURIComponent(this.props.params.id).toLowerCase()
          : undefined,
        this.props.params.name
          ? this.props.params.name.toLowerCase()
          : undefined,
        checked
      );
    });
  };

  updateUrlFromFilter(query, filter, queryProperty, title) {
    if (filter) {
      query.set(queryProperty, filter);
    } else {
      query.delete(queryProperty);
    }
  }

  updateUrl = (providerName, instrument, isTest) => {
    providerName = providerName?.trim();
    instrument = instrument?.trim();
    const query = new URLSearchParams();
    this.updateUrlFromFilter(query, isTest, "isTest", "IsTest");
    console.debug("UpdateUrl: ", providerName, instrument, isTest);
    if (providerName && instrument)
      this.props.navigate(
        `/providerInstruments/${instrument}/providers/${providerName}?${query.toString()}`,
        { replace: true }
      );
    else if (providerName && !instrument)
      this.props.navigate(
        `/providerInstruments/providers/${providerName}?${query.toString()}`,
        { replace: true }
      );
    else if (!providerName && instrument)
      this.props.navigate(
        `/providerInstruments/${instrument}?${query.toString()}`,
        { replace: true }
      );
    else
      this.props.navigate(`/providerInstruments?${query.toString()}`, {
        replace: true,
      });
  };

  render() {
    const { search } = this.state;

    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Row justify="space-between">
            <Col span={16}>
              <Space style={{ padding: "0px 0px 15px 0px" }} wrap>
                <Title level={4}>Search</Title>
                <Input
                  allowClear
                  prefix={<SearchOutlined />}
                  placeholder="#connector, internal, external instrument name or description"
                  onChange={this.onChange}
                  style={{ width: 400 }}
                  value={search}
                />
                <Switch
                  checkedChildren="Test only"
                  unCheckedChildren="Live only"
                  onChange={this.onShowTestChange}
                  checked={this.state.showTest}
                />
              </Space>
            </Col>{" "}
            <Col>
              <Space align="end" style={{ padding: "0px 15px 15px 0px" }} wrap>
                <Tooltip title={`Add a new provider instrument`}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    disabled={!this.context.hasWriteRole}
                    onClick={() => this.addNew()}
                  ></Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
          <ProviderInstrumentTable
            onChange={this.pageChanged}
            loading={this.state.loading}
            currentPage={this.state.currentPage}
            total={this.state.total}
            providerInstruments={this.state.providerInstruments}
          />
        </Content>
      </Layout>
    );
  }
}

ProviderInstrumentSearch.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentSearch);
