import React, { Component } from "react";
import { Form, Select, Divider, Input } from "antd";
import * as _ from "underscore";
import "antd/dist/antd.dark.css";
import "../../../main.css";
import { FormContext } from "./formContext";
import ConnectorsCoordinatorProxy from "../../../proxies/connectorsCoordinatorProxy";
import { RenderHelpers } from "../../../helpers/renderHelpers";

const { Option } = Select;

class SecondaryFeedSettings extends Component {
  static contextType = FormContext;

  constructor(props) {
    super();
    this.throttledSecondarySearch = _.throttle(
      this.onSecondaryFinalSearch,
      2000,
      {
        leading: false,
      }
    );

    this.pricingHubProxy = new ConnectorsCoordinatorProxy();
    this.state = {
      secondaryFeedPopulated: false,
      secondaryProviderInstruments: [],
    };
  }

  componentDidMount() {
    this.formRef = this.context.formRef;
  }

  setInitialValues(instrument) {
    if (instrument.secondaryFeed) {
      this.updateSecondaryFeedPopulated(true);
    }
  }

  onSecondaryFeedChanged = async (value) => {
    if (value) {
      this.updateSecondaryFeedPopulated(true);
      this.props.updateTimeout(true);
      let secondaries;
      if (this.context.isInstrumentAdd) {
        secondaries = [];
      } else {
        secondaries =
          await this.pricingHubProxy.getAvailableProviderInstruments(
            value,
            this.state.primarySearch
          );
      }
      if (this.context.instrument.secondaryFeed === value)
        secondaries.push({
          name: this.context.instrument.secondaryName,
          id: this.context.instrument.secondaryId,
        });
      else {
        secondaries = [];
        this.formRef.current.setFieldsValue({ secondaryName: undefined });
        this.formRef.current.setFieldsValue({ secondaryId: undefined });
      }
      this.setState({
        secondaryProviderInstruments: secondaries,
      });
    } else {
      this.updateSecondaryFeedPopulated(false);
      this.props.updateTimeout(false);
      this.formRef.current.setFieldsValue({ secondaryName: undefined });
      this.formRef.current.setFieldsValue({ secondaryId: undefined });
      this.setState({
        secondaryProviderInstruments: [],
      });
    }
  };

  onSecondarySearch = async (search) => {
    if (search.length > 2) {
      this.setState(
        { secondarySearch: search, secondarySearchLoading: true },
        () => this.throttledSecondarySearch()
      );
    }
  };

  onSecondaryFinalSearch = async () => {
    let instrument = this.formRef.current.getFieldsValue();
    let secondaries =
      await this.pricingHubProxy.getAvailableProviderInstruments(
        instrument.secondaryFeed,
        this.state.secondarySearch
      );
    if (this.context.instrument.secondaryFeed)
      secondaries.push({
        name: this.context.instrument.secondaryName,
        id: this.context.instrument.secondaryId,
        enabled: this.context.instrument.secondaryEnabled,
      });
    this.setState({
      secondaryProviderInstruments: secondaries,
      secondarySearchLoading: false,
    });
  };

  updateSecondaryFeedPopulated(value) {
    this.setState({ secondaryFeedPopulated: value });
    this.props.updateSecondaryFeedPopulated(value);
  }

  onSecondaryProviderInstrumentChanged = (value, option) => {
    let found = this.state.secondaryProviderInstruments.filter(
      (x) => String(x.id) === option.key
    )[0];
    this.formRef.current.setFieldsValue({ secondaryId: found?.id });
  };

  render() {
    return (
      <div>
        <Divider orientation="left">Secondary Provider</Divider>
        <Form.Item name="secondaryId" hidden="true"></Form.Item>
        <Form.Item name="secondaryConnectorMapId" hidden="true"></Form.Item>
        <Form.Item name="secondaryEnabled" hidden="true"></Form.Item>
        <Form.Item
          label="Connector"
          name="secondaryFeed"
        >
          <Select
            options={this.context.providers}
            allowClear="true"
            onChange={this.onSecondaryFeedChanged}
          ></Select>
        </Form.Item>
        {this.state.secondaryFeedPopulated ? (
          <>
            <Form.Item
              label="Symbol"
              name="secondaryName"
              rules={[
                {
                  required: this.state.secondaryFeedPopulated,
                  message: "Symbol is mandatory!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear="true"
                loading={this.state.secondarySearchLoading}
                onSearch={this.onSecondarySearch}
                onChange={this.onSecondaryProviderInstrumentChanged}
              >
                {this.state.secondaryProviderInstruments?.map((x) => (
                  <Option
                    key={x.id}
                    value={
                      x.name + x.cmcId + x.bloombergId + x.isin + x.description
                    }
                    label={
                      x.name + x.cmcId + x.bloombergId + x.isin + x.description
                    }
                  >
                    <div>
                      <b>{x.name}</b>,
                      {RenderHelpers.renderProviderInstruments(
                        x.cmcId,
                        "cmcId"
                      )}
                      ,
                      {RenderHelpers.renderProviderInstruments(
                        x.bloombergId,
                        "bloombergId"
                      )}
                      ,{RenderHelpers.renderProviderInstruments(x.isin, "isin")}
                      , {x.description ? x.description : "-"}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Fair value" name="secondaryFairValue">
              <Input type="number" defaultValue="0" />
            </Form.Item>
          </>
        ) : null}
      </div>
    );
  }
}

SecondaryFeedSettings.contextType = FormContext;
export default SecondaryFeedSettings;
