export class InstrumentExportHelper {
    static extractCsvInstrument(instrument) {
    return {
      id: instrument.id,
      enabled: instrument.enabled,
      name: instrument.publishedName,
      publishedSource: instrument.publishedSource,
      description: instrument.description,
      mt4: instrument.mt4Name,
      timeoutFailover: instrument.timeoutFailover,
      timeoutInSec: instrument.timeoutInSec,
      timeoutInSecAtNight: instrument.timeoutInSecAtNight,
      backwardationFix: instrument.backwardationFix,
      zeroPriceFilter: instrument.zeroPriceFilter,
      maxChangeMovementValue: instrument.maxChangeMovementValue,
      maxChangeNumberOfPrices: instrument.maxChangeNumberOfPrices,
      maxChangeDiscard: instrument.maxChangeDiscard,
    };
  }

  static extractFullCsv(instrument) {
    return {
      id: instrument.id,
      name: instrument.publishedName,
      description: instrument.description,
      "primary feed": instrument.primaryFeed,
      "primary provider instrument": instrument.primaryName,
      "secondary feed": instrument.secondaryFeed,
      "secondary provider instrument": instrument.secondaryName,
      mt4: instrument.mt4Name,
      enabled: instrument.enabled,
      publishedSource: instrument.publishedSource,
      timeoutFailover: instrument.timeoutFailover,
      timeoutInSec: instrument.timeoutInSec,
      timeoutInSecAtNight: instrument.timeoutInSecAtNight,
      backwardationFix: instrument.backwardationFix,
      zeroPriceFilter: instrument.zeroPriceFilter,
      maxChangeMovementValue: instrument.maxChangeMovementValue,
      maxChangeNumberOfPrices: instrument.maxChangeNumberOfPrices,
      maxChangeDiscard: instrument.maxChangeDiscard,
    };
  }
}