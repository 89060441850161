import React, { Component } from "react";
import {
  Layout,
  Table,
  Button,
  Input,
  Tree,
  Spin,
} from "antd";
import {
  SearchOutlined,
  CarryOutOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import * as _ from "underscore";

import "antd/dist/antd.dark.css";
import "../main.css";
import DataRepositoryProxy from "../proxies/dataRepositoryProxy";
import DynamicDataRepositoryMarketWatcherProxy from "../proxies/marketWatcherProxy";
import { MarketHelpers } from "../helpers/marketHelpers";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";

const { Sider, Content } = Layout;
const fullDateFormat = "yyyy-MM-DD HH:mm:ss";
const dateFormat = "ddd HH:mm:ss";

class MarketWatcher extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.dataRepositoryProxy = new DataRepositoryProxy();
    this.marketWatcherProxy = new DynamicDataRepositoryMarketWatcherProxy(
      this.onData,
      this.onReconnect
    );

    this.throttledSubscribe = _.throttle(this.subscribe, 2000, {
      leading: false,
    });

    let columns = [
      {
        title: "Quote Id",
        dataIndex: "id",
        key: "id",
        width: 120,
      },
      {
        title: "Instrument",
        dataIndex: "instrument",
        width: 120,
      },
      {
        title: "Market Name",
        dataIndex: "marketName",
      },
      {
        title: "Market Group",
        dataIndex: "marketGroupName",
      },
      {
        title: "Last seen",
        dataIndex: "sinceLast",
        render: (number) => this.secondsToTimeSpan(number),
      },
      {
        title: "Last Seen",
        dataIndex: "time",
        render: (text) =>
          text ? moment(text).format(fullDateFormat).toString() : "",
      },
      {
        width: 50,
        render: (text, record) => (
          <a
            href={"#/marketMonitor/" + record.marketId}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<AreaChartOutlined />}
            />
          </a>
        ),
      },
    ];

    this.marketQuotes = [];
    this.state = {
      search: undefined,
      columns: columns,
      treeData: [],
      markets: [],
      allMarkets: [],
      marketGroups: [],
      marketSuperGroups: [],
      marketQuotes: this.marketQuotes,
      whiteLabels: [],
      loading: true,
      selectedPlatform: undefined,
      selectedRiskGroup: undefined,
      selectedStatus: undefined,
      selectedRegion: undefined,
      platformsLoading: true,
      statusesLoading: true,
      riskGroupsLoading: true,
      regionsLoading: true,
      filteredOutData: undefined,
      openPositionsLoading: true,
      expandedKeys: [],
    };
  }

  secondsToTimeSpan(seconds) {
    const years = Math.floor(seconds / (365 * 24 * 3600));
    const months = Math.floor((seconds % (365 * 24 * 3600)) / (30 * 24 * 3600));
    const days = Math.floor((seconds % (30 * 24 * 3600)) / (24 * 3600));

    const timeSpan = [];

    if (years > 0) timeSpan.push(`${years} ${years === 1 ? "year" : "years"}`);
    if (months > 0)
      timeSpan.push(`${months} ${months === 1 ? "month" : "months"}`);
    if (days > 0) timeSpan.push(`${days} ${days === 1 ? "day" : "days"}`);

    return timeSpan.join(", ");
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.search(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.search(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.reset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex] !== null
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "yellow", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  search = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  reset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onSelect = async (selectedKeys, info) => {
    console.debug("Selected: ", selectedKeys, info);
    this.setState({ selectedKeys: selectedKeys });
  };

  updateMarkets = async (markets) => {
    let tradeableData = await this.redisProxy.tradableMarket(
      markets.map((x) => x.id)
    );
    tradeableData.forEach((x) => {
      let market = markets.filter((y) => y.id === x.marketId)[0];
      if (market) {
        market.exchangeOpen = x.exchangeOpen;
        market.isHoliday = x.isHoliday;
        market.isInTradeSession = x.isInTradeSession;
      }
    });
  };

  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  sort = (list, property) => {
    list.sort(function (a, b) {
      if (property(a) < property(b)) {
        return -1;
      }
      if (property(a) > property(b)) {
        return 1;
      }
      return 0;
    });
  };

  onData = async (data) => {
    if (this._isMounted) {
      const { marketSuperGroups } = this.state;
      data.forEach((x) => {
        x.marketGroupName = marketSuperGroups.filter(
          (y) => y.id === x.marketSuperGroupId
        )[0].name;
        x.instrument =
          MarketHelpers.extractInstrumentNameFromPrcGenRawSourceSymbol(
            x.prcGenRawSourceSymbol
          );
      });
      this.setState({
        marketQuotes: [...data],
      });
    }
    let lastUpdate = moment(Date.now()).format(dateFormat).toString();
    this.setState({ lastUpdate: lastUpdate });
  };

  onReconnect = () => {
    if (this._isMounted) return this.state.checkedKeys;
    return [];
  };

  async componentDidMount() {
    this._isMounted = true;
    let state = this.context.state;
    let selectedInstrument = decodeURIComponent(this.props.params.instrument);
    console.debug("Selected instrument:", selectedInstrument);
    this.setState({ selectedInstrument: selectedInstrument });
    await this.marketWatcherProxy.connect();

    let whiteLabels = state.whiteLabels;
    if (!whiteLabels) {
      whiteLabels = await this.dataRepositoryProxy.whiteLabels();
      this.context.updateContext("whiteLabels", whiteLabels);
    }

    let markets = state.markets;
    if (!markets) {
      markets = await this.dataRepositoryProxy.markets();
      this.context.updateContext("markets", markets);
    }

    let marketGroups = state.marketGroups;
    if (!marketGroups) {
      marketGroups = await this.dataRepositoryProxy.marketGroups();
      this.context.updateContext("marketGroups", marketGroups);
    }

    let marketSuperGroups = state.marketSuperGroups;
    if (!marketSuperGroups) {
      marketSuperGroups = await this.dataRepositoryProxy.marketSuperGroups();
      this.context.updateContext("marketSuperGroups", marketSuperGroups);
    }

    let groupedBySuperGroup = this.groupBy(
      markets,
      (x) => x.marketSuperGroupId
    );
    let superGroups = [];
    groupedBySuperGroup.forEach((x) => {
      let foundMarketSuperGroup = marketSuperGroups.filter(
        (y) => y.id === x[0].marketSuperGroupId
      )[0];
      superGroups.push({
        title: foundMarketSuperGroup.name.trim(),
        key: foundMarketSuperGroup.id,
        icon: <CarryOutOutlined />,
        children: [],
      });
    });
    let decodedSearch = decodeURIComponent(state.mmSearch);
    this.sort(markets, (x) => x.name);
    this.sort(superGroups, (x) => x.title);
    this.setState({ treeData: superGroups });
    this.setState({ allMarkets: markets });
    this.setState({ whiteLabels: whiteLabels });
    this.setState({ marketGroups: marketGroups });
    this.setState({ marketSuperGroups: marketSuperGroups });
    this.setState({ platformsLoading: false });
    this.setState({ statusesLoading: false });
    this.setState({ riskGroupsLoading: false });
    this.setState({ regionsLoading: false });
    this.setState({ mmSearch: decodedSearch });
    this.setState({ selectedKeys: state.selectedTree });
    this.setState({ expandedKeys: state.expandedTree });
    this.setState({ loading: false });

    this.setState({
      checkedKeys: state.marketWatcherCheckedKeys
        ? state.marketWatcherCheckedKeys
        : superGroups.map((x) => x.key),
    });
    this.marketWatcherProxy.subscribeAndOverride(
      state.marketWatcherCheckedKeys
        ? state.marketWatcherCheckedKeys
        : superGroups.map((x) => x.key)
    );
    this.context.updateMenuSelection("marketWatcher");
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.marketWatcherProxy.stop();
  };

  onCheck = async (checkedKeys, info) => {
    this.setState({ checkedKeys: checkedKeys });
    this.context.updateContext("marketWatcherCheckedKeys", checkedKeys);
    if (checkedKeys.length > 0) {
      this.setState({ selectedMarketSuperGroupIds: checkedKeys });
      this.throttledSubscribe();
    } else {
      await this.marketWatcherProxy.unsubscribe();
      this.setState({
        marketQuotes: [],
      });
    }
  };

  subscribe = async () => {
    await this.marketWatcherProxy.subscribeAndOverride(
      this.state.selectedMarketSuperGroupIds
    );
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Layout className="layout">
          <Sider>
            <Tree
              checkable
              treeData={this.state.treeData}
              onSelect={this.onSelect}
              onCheck={this.onCheck}
              checkedKeys={this.state.checkedKeys}
            />
          </Sider>
          <Content style={{ padding: "0 5px" }}>
            The last update took place on <b>{this.state.lastUpdate}</b>
            <div className="site-layout-content">
              <Table
                rowKey="id"
                size="middle"
                columns={this.state.columns}
                dataSource={this.state.marketQuotes}
                pagination={true}
              />
            </div>
          </Content>
        </Layout>
      </Spin>
    );
  }
}
MarketWatcher.contextType = AppContext;
export default Helpers.withParams(MarketWatcher);
